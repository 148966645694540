/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';

import { DateRangeInput } from 'components/custom-date-picker/date-range-input/date-range-input';
import { Colors } from 'constants/colors';
import { fontSecondary } from 'constants/font-family';
import { FontSizes } from 'constants/font-sizes';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import React, { useEffect } from 'react';
import { StyledButton } from 'components/button/button';
import dayjs, { ManipulateType } from 'dayjs';
import moment from 'moment';
import {
  DATE_PICKER_BUTTON,
  DATE_PICKER_CUSTOM_PERIOD,
  DATE_PICKER_MENU_ITEM,
} from 'constants/test-ids';
import { useDispatch } from 'react-redux';
import {
  setActivityEndDate,
  setActivityPeriod,
  setActivityStartDate,
  setCalendarTab,
} from 'store/modules/identities/identities.reducer';
import {
  useActivityPeriodSelector,
  useCalendarTabSelector,
} from 'store/modules/identities/identities.selector';

export enum Periods {
  'weeks',
  'months',
  'years',
  'custom',
}

interface Props {
  tabsList: string[];
  minDate?: Date | null;
}

export const DatePicker = ({ tabsList, minDate }: Props) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const openMenu = Boolean(anchorEl);
  const tabValue = tabsList[useCalendarTabSelector()];
  const setTabValue = (tabValue: string): void => {
    const index = tabsList.indexOf(tabValue);
    dispatch(setCalendarTab(index));
  };
  const activityPeriod = useActivityPeriodSelector();
  useEffect(() => {
    if (tabValue) onSelectPeriod(tabValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectPeriod = (period: string) => {
    setTabValue(period);

    if (period === 'custom') {
      dispatch(setActivityPeriod(period));
      return;
    }

    const duration = parseInt(period.split(' ')[1]);
    const units = period.split(' ')[2];

    dispatch(
      setActivityStartDate(
        dayjs(new Date())
          .subtract(duration, units as ManipulateType)
          .format('YYYY-MM-DDTHH:mm:ss'),
      ),
    );
    dispatch(
      setActivityEndDate(dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss')),
    );
    dispatch(setActivityPeriod(Periods[1]));
  };

  const onSubmitHandler = () => {
    dispatch(
      setActivityStartDate(
        startDate ? dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss') : undefined,
      ),
    );
    dispatch(
      setActivityEndDate(
        endDate ? dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss') : undefined,
      ),
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsToggle(false);
    if (tabValue === 'custom' && !startDate) onSelectPeriod(tabsList[0]);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isToggle, setIsToggle] = React.useState(false);

  return (
    <Box>
      <IconButton
        id="period-button"
        aria-controls={openMenu ? 'period-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          minWidth: '120px',
          height: '36px',
          borderRadius: '5px',
          border: `1px solid ${Colors.blackPearl}`,
          padding: '5px 10px',
          gap: '5px',
        }}
        data-testid={DATE_PICKER_BUTTON}
      >
        <Typography
          sx={{
            color: Colors.blackPearl,
            fontFamily: fontSecondary,
            fontSize: FontSizes.fontSize14,
            lineHeight: '22px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CalendarIcon style={{ marginRight: '5px' }} />
          {tabValue
            ? tabValue
            : startDate && endDate
            ? startDate.getFullYear() === endDate.getFullYear()
              ? startDate.getMonth() === endDate.getMonth()
                ? `${moment(startDate).format('MMM DD')} - ${moment(
                    endDate,
                  ).format('DD, YYYY')}`
                : `${moment(startDate).format('MMM DD')} - ${moment(
                    endDate,
                  ).format('MMM DD, YYYY')}`
              : `${moment(startDate).format('MMM DD, YYYY')} - ${moment(
                  endDate,
                ).format('MMM DD, YYYY')}`
            : 'Custom'}
        </Typography>
      </IconButton>
      <Menu
        id="period-menu"
        MenuListProps={{
          'aria-labelledby': 'period-button',
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        sx={{
          padding: '0px',
          position: 'absolute',
          top: '8px',
          left: '0px',
          '& .MuiList-padding': {
            padding: '0px',
            borderRadius: '10px',
          },
        }}
      >
        <MenuList
          sx={{
            backgroundColor: Colors.white,
            padding: '0px',
            gap: '10px',
            borderRadius: '10px',
            boxShadow: `0px 4px 4px 0px ${Colors.tooltipShadow}`,
            width: '119px',
          }}
        >
          {tabsList?.map((tab) => (
            <MenuItem onClick={handleClose} disableRipple>
              <StyledButton
                onClick={() => onSelectPeriod(tab)}
                variant="text"
                color={'primary'}
                size="small"
                sx={{
                  padding: '0px',
                  color: `${
                    tabValue === tab
                      ? `${Colors.binge} !important`
                      : Colors.darkGray
                  }`,
                }}
                data-testid={DATE_PICKER_MENU_ITEM}
              >
                {tab}
              </StyledButton>
            </MenuItem>
          ))}
          <MenuItem disableRipple>
            <StyledButton
              onClick={() => {
                onSelectPeriod('custom');
                setIsToggle(true);
              }}
              variant="text"
              color={'primary'}
              size="small"
              sx={{
                color: `${
                  tabValue === 'custom'
                    ? `${Colors.binge} !important`
                    : Colors.darkGray
                }`,
              }}
              startIcon={<KeyboardArrowLeft />}
              data-testid={DATE_PICKER_CUSTOM_PERIOD}
            >
              Custom
            </StyledButton>
          </MenuItem>
        </MenuList>
      </Menu>
      {activityPeriod === 'custom' && isToggle && (
        <DateRangeInput
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          onSubmitHandler={onSubmitHandler}
          isToggle={isToggle}
          setIsToggle={setIsToggle}
          resetDates={() => onSelectPeriod(tabsList[1])}
        />
      )}
    </Box>
  );
};

import { FilterItem } from 'components/multi-filter/multi-filter';
import { TableColumnProps } from 'components/table/table-component/table-component';

export const OSSDetailsTableStructure: TableColumnProps[] = [
  {
    id: 'pkg',
    title: 'Package Name',
    withSort: true,
    position: 'left',
  },
  {
    id: 'repo',
    title: 'Repository',
    withSort: true,
    position: 'left',
  },
  {
    id: 'license',
    title: 'License',
    withSort: true,
    position: 'left',
  },
  {
    id: 'package_mgr',
    title: 'Package Manager',
    withSort: true,
    position: 'left',
  },
  {
    id: 'highest_active_severity',
    title: 'Vulnerability',
    withSort: true,
    position: 'left',
    isDate: true,
  },
  {
    id: 'imported_date',
    title: 'Import Date',
    withSort: true,
    position: 'left',
    isDate: true,
  },
];

export const OSSDetailsFilterList: FilterItem[] = [
  {
    title: 'Vulnerability',
    filterId: 'severity',
    filterColumns: ['highest_active_severity'],
    props: [
      {
        label: 'Critical',
        value: 'CRITICAL',
      },
      {
        label: 'High',
        value: 'HIGH',
      },
      {
        label: 'Moderate',
        value: 'MODERATE',
      },
      {
        label: 'Low',
        value: 'LOW',
      },
    ],
  },
  {
    title: 'Is top level',
    filterId: 'isTopLevel',
    filterColumns: ['pkg'],
    props: [
      {
        label: 'Is top level',
        value: true,
      },
      {
        label: 'Is not top level',
        value: false,
      },
    ],
  },
];

import { TableColumnProps } from '../../components/table/table-component/table-component';

export const tableStructure: TableColumnProps[] = [
  {
    id: 'title',
    title: 'Title',
    position: 'left',
    withSort: true,
  },
  {
    id: 'source',
    title: 'Source',
    position: 'left',
    withSort: true,
  },
  {
    id: 'owner',
    title: 'Organization',
    position: 'left',
    withSort: true,
  },
  {
    id: 'repository',
    title: 'Repo',
    position: 'left',
    withSort: true,
  },
  {
    id: 'author_login',
    title: 'User',
    position: 'left',
    withSort: true,
  },
  {
    id: 'severity',
    title: 'Severity',
    position: 'left',
    withSort: true,
  },
  {
    id: 'file_path',
    title: 'File',
    position: 'left',
  },
  {
    id: 'causeMetadata',
    title: 'Supporting Data',
    position: 'left',
  },
];

export const mockData = [
  {
    type: 'Terraform Security Check',
    id: 'AVD-AWS-0057',
    avdid: 'AVD-AWS-0057',
    title:
      'IAM policy should avoid use of wildcards and instead apply the principle of least privilege',
    description:
      'You should use the principle of least privilege when defining your IAM policies. This means you should specify each exact permission required without using wildcards, as this could cause the granting of access to certain undesired actions, resources and principals.',
    message:
      "IAM policy document uses sensitive action 'ecr:GetDownloadUrlForLayer' on wildcarded resource '*'",
    query: 'data..',
    resolution:
      'Specify the exact permissions required, and to which resources they should apply instead of using wildcards.',
    severity: 'HIGH',
    primaryURL: 'https://avd.aquasec.com/misconfig/avd-aws-0057',
    references: [
      'https://docs.aws.amazon.com/IAM/latest/UserGuide/best-practices.html',
      'https://avd.aquasec.com/misconfig/avd-aws-0057',
    ],
    status: 'FAIL',
    layer: {},
    causeMetadata: {
      Resource: 'aws_iam_policy.ecr[5]',
      Provider: 'AWS',
      Service: 'iam',
      StartLine: 468,
      EndLine: 468,
      Code: {
        Lines: [
          {
            Number: 436,
            Content: 'resource "aws_iam_policy" "ecr" {',
            IsCause: false,
            Annotation: '',
            Truncated: false,
            Highlighted:
              '\u001b[38;5;33mresource\u001b[0m \u001b[38;5;37m"aws_iam_policy"\u001b[0m \u001b[38;5;37m"ecr"\u001b[0m {',
            FirstCause: false,
            LastCause: false,
          },
          {
            Number: 437,
            Content: '',
            IsCause: false,
            Annotation: '',
            Truncated: true,
            FirstCause: false,
            LastCause: false,
          },
          {
            Number: 468,
            Content: '            "Resource": "*"',
            IsCause: true,
            Annotation: '',
            Truncated: false,
            Highlighted: '            "Resource": "*"',
            FirstCause: true,
            LastCause: true,
          },
          {
            Number: 476,
            Content: '',
            IsCause: false,
            Annotation: '',
            Truncated: true,
            FirstCause: false,
            LastCause: false,
          },
          {
            Number: 477,
            Content: '}',
            IsCause: false,
            Annotation: '',
            Truncated: false,
            Highlighted: '}',
            FirstCause: false,
            LastCause: false,
          },
        ],
      },
      Occurrences: [
        {
          Resource: 'aws_iam_policy.ecr[0].policy',
          Filename: 'core-infra/airbyte-public-ec2.tf',
          Location: {
            StartLine: 444,
            EndLine: 469,
          },
        },
        {
          Resource: 'aws_iam_policy.ecr[0].policy',
          Filename: 'core-infra/airbyte-public-ec2.tf',
          Location: {
            StartLine: 440,
            EndLine: 472,
          },
        },
        {
          Resource: 'aws_iam_policy.ecr[0]',
          Filename: 'core-infra/airbyte-public-ec2.tf',
          Location: {
            StartLine: 436,
            EndLine: 477,
          },
        },
      ],
    },
  },
  {
    type: 'Terraform Security Check',
    id: 'AVD-AWS-0104',
    avdid: 'AVD-AWS-0104',
    title: 'An egress security group rule allows traffic to /0.',
    description:
      'Opening up ports to connect out to the public internet is generally to be avoided. You should restrict access to IP addresses or ranges that are explicitly required where possible.',
    message:
      'Security group rule allows egress to multiple public internet addresses.',
    query: 'data..',
    resolution: 'Set a more restrictive cidr range',
    severity: 'CRITICAL',
    primaryURL: 'https://avd.aquasec.com/misconfig/avd-aws-0104',
    references: [
      'https://docs.aws.amazon.com/whitepapers/latest/building-scalable-secure-multi-vpc-network-infrastructure/centralized-egress-to-internet.html',
      'https://avd.aquasec.com/misconfig/avd-aws-0104',
    ],
    status: 'FAIL',
    layer: {},
    causeMetadata: {
      Resource: 'aws_security_group.public_tools[0]',
      Provider: 'AWS',
      Service: 'ec2',
      StartLine: 64,
      EndLine: 64,
      Code: {
        Lines: [
          {
            Number: 33,
            Content: 'resource "aws_security_group" "public_tools" {',
            IsCause: false,
            Annotation: '',
            Truncated: false,
            Highlighted:
              '\u001b[38;5;33mresource\u001b[0m \u001b[38;5;37m"aws_security_group"\u001b[0m \u001b[38;5;37m"public_tools"\u001b[0m {',
            FirstCause: false,
            LastCause: false,
          },
          {
            Number: 34,
            Content: '',
            IsCause: false,
            Annotation: '',
            Truncated: true,
            FirstCause: false,
            LastCause: false,
          },
          {
            Number: 64,
            Content: '    cidr_blocks = ["0.0.0.0/0"]',
            IsCause: true,
            Annotation: '',
            Truncated: false,
            Highlighted:
              '\u001b[0m    \u001b[38;5;245mcidr_blocks\u001b[0m = [\u001b[38;5;37m"0.0.0.0/0"\u001b[0m]',
            FirstCause: true,
            LastCause: true,
          },
          {
            Number: 71,
            Content: '',
            IsCause: false,
            Annotation: '',
            Truncated: true,
            FirstCause: false,
            LastCause: false,
          },
          {
            Number: 72,
            Content: '}',
            IsCause: false,
            Annotation: '',
            Truncated: false,
            Highlighted: '}',
            FirstCause: false,
            LastCause: false,
          },
        ],
      },
      Occurrences: [
        {
          Resource: 'egress',
          Filename: 'core-infra/airbyte-public-ec2.tf',
          Location: {
            StartLine: 60,
            EndLine: 65,
          },
        },
        {
          Resource: 'aws_security_group.public_tools[0]',
          Filename: 'core-infra/airbyte-public-ec2.tf',
          Location: {
            StartLine: 33,
            EndLine: 72,
          },
        },
      ],
    },
  },
  {
    type: 'Terraform Security Check',
    id: 'AVD-AWS-0124',
    avdid: 'AVD-AWS-0124',
    title: 'Missing description for security group rule.',
    description:
      'Security group rules should include a description for auditing purposes.\n\nSimplifies auditing, debugging, and managing security groups.',
    message: 'Security group rule does not have a description.',
    query: 'data..',
    resolution: 'Add descriptions for all security groups rules',
    severity: 'LOW',
    primaryURL: 'https://avd.aquasec.com/misconfig/avd-aws-0124',
    references: [
      'https://www.cloudconformity.com/knowledge-base/aws/EC2/security-group-rules-description.html',
      'https://avd.aquasec.com/misconfig/avd-aws-0124',
    ],
    status: 'FAIL',
    layer: {},
    causeMetadata: {
      Resource: 'aws_security_group.public_tools[8]',
      Provider: 'AWS',
      Service: 'ec2',
      StartLine: 60,
      EndLine: 65,
      Code: {
        Lines: [
          {
            Number: 33,
            Content: 'resource "aws_security_group" "public_tools" {',
            IsCause: false,
            Annotation: '',
            Truncated: false,
            Highlighted:
              '\u001b[38;5;33mresource\u001b[0m \u001b[38;5;37m"aws_security_group"\u001b[0m \u001b[38;5;37m"public_tools"\u001b[0m {',
            FirstCause: false,
            LastCause: false,
          },
          {
            Number: 34,
            Content: '',
            IsCause: false,
            Annotation: '',
            Truncated: true,
            FirstCause: false,
            LastCause: false,
          },
          {
            Number: 60,
            Content: '  egress {',
            IsCause: true,
            Annotation: '',
            Truncated: false,
            Highlighted: '  egress {',
            FirstCause: true,
            LastCause: false,
          },
          {
            Number: 61,
            Content: '    from_port   = 0',
            IsCause: true,
            Annotation: '',
            Truncated: false,
            Highlighted:
              '    \u001b[38;5;245mfrom_port\u001b[0m   = \u001b[38;5;37m0',
            FirstCause: false,
            LastCause: false,
          },
          {
            Number: 62,
            Content: '    to_port     = 0',
            IsCause: true,
            Annotation: '',
            Truncated: false,
            Highlighted:
              '\u001b[0m    \u001b[38;5;245mto_port\u001b[0m     = \u001b[38;5;37m0',
            FirstCause: false,
            LastCause: false,
          },
          {
            Number: 63,
            Content: '    protocol    = "-1"',
            IsCause: true,
            Annotation: '',
            Truncated: false,
            Highlighted:
              '\u001b[0m    \u001b[38;5;245mprotocol\u001b[0m    = \u001b[38;5;37m"-1"',
            FirstCause: false,
            LastCause: false,
          },
          {
            Number: 64,
            Content: '    cidr_blocks = ["0.0.0.0/0"]',
            IsCause: true,
            Annotation: '',
            Truncated: false,
            Highlighted:
              '\u001b[0m    \u001b[38;5;245mcidr_blocks\u001b[0m = [\u001b[38;5;37m"0.0.0.0/0"\u001b[0m]',
            FirstCause: false,
            LastCause: false,
          },
          {
            Number: 65,
            Content: '  }',
            IsCause: true,
            Annotation: '',
            Truncated: false,
            Highlighted: '  }',
            FirstCause: false,
            LastCause: true,
          },
          {
            Number: 71,
            Content: '',
            IsCause: false,
            Annotation: '',
            Truncated: true,
            FirstCause: false,
            LastCause: false,
          },
          {
            Number: 72,
            Content: '}',
            IsCause: false,
            Annotation: '',
            Truncated: false,
            Highlighted: '}',
            FirstCause: false,
            LastCause: false,
          },
        ],
      },
      Occurrences: [
        {
          Resource: 'aws_security_group.public_tools[0]',
          Filename: 'core-infra/airbyte-public-ec2.tf',
          Location: {
            StartLine: 33,
            EndLine: 72,
          },
        },
      ],
    },
  },
];

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import {
  TableLoader,
  TableRowEmptyState,
} from '../table-components/table-components';
import { Colors } from 'constants/colors';
import { StyledTableBodyCell, StyledTableHeaderCell } from '../styles';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FilterRenderer } from '../table-filters-components/filter-renderer/filter-renderer';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { ReactComponent as FilledFilterIcon } from 'assets/icons/filter_filled.svg';
import { ReactComponent as FilteredColumnIcon } from 'assets/icons/filtered-column.svg';
import { ReactComponent as EntityGraphIcon } from 'assets/icons/graph.svg';
import { PaginationComponent } from './pagination';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  FiltersTableName,
  setFiltersForTable,
} from 'store/modules/filters/filters.reducer';
import { useNavigate } from 'react-router-dom';
import { paths } from 'constants/routes';
import { FontSizes } from 'constants/font-sizes';
import { TABLE_COMPONENT_ENTITY_GRAPH_LINK } from 'constants/test-ids';
import {
  setItemsPerPage,
  setPageNumber,
} from 'store/modules/pagination/pagination.reducer';
import { usePaginationSelector } from 'store/modules/pagination/pagination.selector';
import { useSelectedColumnSelector } from 'store/modules/selected-column/selected-column.selector';
import { setHoveredRow } from 'store/modules/identities/identities.reducer';
import {
  setTableItemsPerPage,
  setTablePageNumber,
} from 'store/modules/selected-column/selected-column.reducer';

export interface ExtraCellsObject {
  [key: string]: (item: any) => ReactNode;
}

export interface TableColumnProps {
  id: string;
  title: string;
  withSort?: boolean;
  position?: 'center' | 'left' | 'right' | 'inherit' | 'justify';
  isDate?: boolean;
  dateFormat?: string;
  sortColumn?: string;
  customComponent?: (data: any) => ReactNode;
}

export interface TablePageProps {
  tableId: string;
  tableHeight?: string;
  isListLoading: boolean;
  dataTestId?: string;
  isListErrored: boolean;
  isEmptyList: boolean;
  isListFetching: boolean;
  list: Record<string, any>[];
  tableColumns: TableColumnProps[];
  extraCellsRenderObj?: ExtraCellsObject;
  onRowClick?: (item: any) => void;
  columnFilterClickHandler?: (item: TableColumnProps, coordinates: any) => void;
  notPaginated?: boolean;
  invalidateFilters?: boolean;
  tableMinHeight?: string;
}

export const TableComponent = ({
  tableId,
  dataTestId,
  isListLoading,
  list,
  isListFetching,
  isListErrored,
  isEmptyList,
  tableColumns,
  extraCellsRenderObj,
  tableHeight,
  onRowClick,
  columnFilterClickHandler,
  notPaginated,
  tableMinHeight,
}: TablePageProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentSelectedRow, setCurrentSelectedRow] = useState<
    number | undefined
  >(-1);

  const selectedColumnFilter = useSelectedColumnSelector(tableId);

  let total = usePaginationSelector().totalItems;
  let page = usePaginationSelector().page;
  let pageSize = usePaginationSelector().itemsPerPage;

  if (selectedColumnFilter) {
    total = selectedColumnFilter.totalItems;
    page = selectedColumnFilter.page;
    pageSize = selectedColumnFilter.itemsPerPage;
  }
  const pageChange = (value: number) => {
    dispatch(setPageNumber(value));
    if (selectedColumnFilter) {
      dispatch(
        setTablePageNumber({
          table: tableId,
          page: value,
        }),
      );
    }
  };

  const handlePageSizeChange = (event: any) => {
    const newPageSize = event.target.value;
    dispatch(setItemsPerPage(newPageSize));
    dispatch(setPageNumber(1));
    if (selectedColumnFilter) {
      dispatch(
        setTableItemsPerPage({
          table: tableId,
          itemsPerPage: newPageSize,
        }),
      );
      dispatch(
        setTablePageNumber({
          table: tableId,
          page: 1,
        }),
      );
    }
  };

  const count = Math.ceil(total ? total / pageSize : 1);

  const goToEntityGraph = (data: any) => {
    const filterObject: Record<
      string,
      string | boolean | string[] | number | undefined | any[]
    > = {
      selectedUsers: [{ ...data, user_id: data?.login || data?.display_name }],
      initialFilterSelection: 'identity',
      currentFilterCount: 1,
    };
    dispatch(
      setFiltersForTable({
        tableName: FiltersTableName.ENTITY_GRAPH,
        filters: filterObject,
      }),
    );
    navigate(`${paths.entityGraph}`);
  };

  const RenderTableHeaderContent = () => {
    const selectedColumnFilter = useSelectedColumnSelector(tableId);
    const filters = selectedColumnFilter?.tableFilterChips
      ?.filterTitles as any[];
    const filterSet = new Set([
      selectedColumnFilter?.tableFilterChips?.sorting_field,
      ...(filters ? filters.map((title: any) => title.column) : []),
    ]);
    const filteredColumns = Array.from(filterSet);

    return (
      <TableRow>
        {tableColumns.map((column, index) => (
          <StyledTableHeaderCell
            sx={{
              // px: tableColumns[0]?.id === 'expand' ? '0px' : null,
              maxWidth:
                column.id === 'overprivilege_score' ||
                column.id === 'team_vulnerability_score' ||
                column.id === 'vulnerabilities'
                  ? '200px'
                  : null,
              whiteSpace:
                column.id === 'overprivilege_score' ||
                column.id === 'team_vulnerability_score' ||
                column.id === 'vulnerabilities'
                  ? 'normal'
                  : null,
            }}
            key={column.id}
            withSort={column.withSort}
            alignSortArrow={
              column.id === 'overprivilege_score' ||
              column.id === 'vulnerabilities'
            }
            clickColumnFilter={(e) =>
              columnFilterClickHandler &&
              columnFilterClickHandler(column, { x: e.clientX, y: e.clientY })
            }
            align={column.position || 'center'}
            AssignedFilterCellIcon={
              column.withSort ? (
                selectedColumnFilter?.highlightColumns?.findIndex(
                  (col) => col?.id === column.id,
                ) !== -1 && filteredColumns.includes(column.id) ? (
                  <FilteredColumnIcon color={Colors.binge} />
                ) : column.id === selectedColumnFilter?.previousColumn ? (
                  <FilledFilterIcon color={Colors.blackPearl} />
                ) : (
                  <FilterIcon color={Colors.blackPearl} />
                )
              ) : undefined
            }
          >
            {column.title}
            {column?.id === selectedColumnFilter?.previousColumn ? (
              <FilterRenderer
                tableId={tableId}
                table={selectedColumnFilter?.previousTable}
                isLast={index > 0 && index >= tableColumns.length - 2}
              />
            ) : null}
          </StyledTableHeaderCell>
        ))}
      </TableRow>
    );
  };

  const RenderTableBodyContent = () => {
    if (isListLoading) {
      return <TableLoader colCount={tableColumns.length} rows={pageSize} />;
    }

    if (isListErrored) {
      return (
        <TableRowEmptyState
          colCount={tableColumns.length}
          height="70vh"
          title="Error"
        />
      );
    }

    if (list && !isEmptyList) {
      return (
        <>
          {list.map((item, index) => (
            <>
              <TableRow
                onMouseEnter={() => dispatch(setHoveredRow(item.user_id))}
                onMouseLeave={() => dispatch(setHoveredRow(null))}
                sx={{
                  backgroundColor:
                    currentSelectedRow === index ? Colors.blackPearl : null,
                  '&.MuiTableRow-hover:hover': {
                    backgroundColor: Colors.tableHover,
                  },
                  '&:nth-of-type(odd)': {
                    backgroundColor: Colors.white,
                  },
                  '&:nth-of-type(even)': {
                    backgroundColor: Colors.tableCell,
                  },
                }}
                data-testid={dataTestId}
                hover
                key={index}
                // ref={list.length === index + 1 ? lastElementRef : null}
                onClick={() => (onRowClick ? onRowClick(item) : null)}
              >
                {tableColumns.map((column) => {
                  if (column.customComponent) {
                    return (
                      <StyledTableBodyCell
                        key={column.id}
                        align={column.position || 'center'}
                        sx={{
                          // px: tableColumns[0]?.id === 'expand' ? '10px' : null,
                          borderTop: `1px solid ${Colors.cellBorder}`,
                        }}
                      >
                        {column.customComponent(item)}
                      </StyledTableBodyCell>
                    );
                  }

                  if (extraCellsRenderObj && extraCellsRenderObj[column.id]) {
                    return (
                      <StyledTableBodyCell
                        key={column.id}
                        align="left"
                        width={column.id === 'count' ? '32px' : 'auto'}
                        sx={{
                          px: column.id === 'expand' ? '5px' : null,
                          borderTop: `1px solid ${Colors.cellBorder}`,
                        }}
                        onClick={() => {
                          if (column.id === 'expand') {
                            setCurrentSelectedRow(
                              index === currentSelectedRow ? 1 : index,
                            );
                          }
                        }}
                      >
                        {column.id === 'expand' ? (
                          <>
                            {index === currentSelectedRow ? (
                              <ArrowDropDownIcon
                                sx={{
                                  color: Colors.blackPearl,
                                  cursor: 'pointer',
                                  fontSize: FontSizes.fontSize14,
                                }}
                              />
                            ) : (
                              <ArrowRightIcon
                                sx={{
                                  color: Colors.blackPearl,
                                  cursor: 'pointer',
                                  fontSize: FontSizes.fontSize14,
                                }}
                              />
                            )}
                          </>
                        ) : (
                          extraCellsRenderObj[column.id](item)
                        )}
                      </StyledTableBodyCell>
                    );
                  }

                  if (column.isDate) {
                    return (
                      <StyledTableBodyCell
                        key={column.id}
                        align={column.position || 'center'}
                        sx={{
                          color: Colors.blackPearl,
                          borderTop: `1px solid ${Colors.cellBorder}`,
                          borderLeft: 'none',
                          minWidth: '130px',
                        }}
                      >
                        {item[column.id]
                          ? moment(item[column.id]).format('MMM DD, YYYY - LT')
                          : '-'}
                      </StyledTableBodyCell>
                    );
                  }

                  return (
                    <StyledTableBodyCell
                      key={column.id}
                      align={column.position || 'center'}
                      sx={{
                        // px: tableColumns[0]?.id === 'expand' ? '0px' : null,
                        borderTop: `1px solid ${Colors.cellBorder}`,
                        color: Colors.blackPearl,
                      }}
                    >
                      {column.id === 'graph' ? (
                        <Button
                          sx={{
                            minWidth: 0,
                            padding: '10px',
                          }}
                          onClick={() => goToEntityGraph(item)}
                          data-testid={TABLE_COMPONENT_ENTITY_GRAPH_LINK}
                        >
                          <EntityGraphIcon
                            style={{
                              color: Colors.binge,
                              width: '20px',
                              height: '20px',
                            }}
                          />
                        </Button>
                      ) : item[column.id] ? (
                        item[column.id]
                      ) : (
                        '-'
                      )}
                    </StyledTableBodyCell>
                  );
                })}
              </TableRow>
              {typeof currentSelectedRow === 'number' &&
              currentSelectedRow === index ? (
                <TableRow
                  onMouseEnter={() => dispatch(setHoveredRow(item.user_id))}
                  onMouseLeave={() => dispatch(setHoveredRow(null))}
                  sx={{
                    backgroundColor:
                      currentSelectedRow === index
                        ? Colors.sectionBackground
                        : null,
                  }}
                >
                  <TableCell
                    style={{
                      padding: 0,
                      borderTop: `1px solid ${Colors.cellBorder}`,
                    }}
                    colSpan={tableColumns?.length}
                  >
                    <Collapse
                      in={currentSelectedRow === index}
                      timeout="auto"
                      unmountOnExit
                    >
                      {extraCellsRenderObj?.expand(item)}
                    </Collapse>
                  </TableCell>
                </TableRow>
              ) : null}
            </>
          ))}
          {!isListLoading && isListFetching && (
            <TableLoader colCount={tableColumns.length} />
          )}
        </>
      );
    } else {
      return (
        <TableRowEmptyState
          colCount={tableColumns.length}
          height="200px"
          title="Nothing to display"
        />
      );
    }
  };

  return (
    <Box width={'100%'}>
      <TableContainer
        sx={{
          marginTop: '15px',
          maxHeight: '80vh',
          minHeight: tableMinHeight || '50vh',
          height: tableHeight,
          marginBottom: '15px',
          maxWidth: 'calc(100vw)',
          border: `1px solid ${Colors.tableBorder}`,
        }}
      >
        <Table
          stickyHeader
          sx={{
            minWidth: '550px',
            backgroundColor: 'transparent',
          }}
          size="small"
        >
          <TableHead sx={{ height: notPaginated ? '42px' : '44px' }}>
            <RenderTableHeaderContent />
          </TableHead>
          <TableBody sx={{ border: `1px solid ${Colors.tableBorder}` }}>
            <RenderTableBodyContent />
          </TableBody>
        </Table>
      </TableContainer>

      {!notPaginated && !isEmptyList && (
        <PaginationComponent
          total={total}
          count={count}
          page={page}
          pageChange={pageChange}
          pageSize={pageSize}
          handlePageSizeChange={handlePageSizeChange}
        />
      )}
    </Box>
  );
};

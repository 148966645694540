import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import {
  IdentitiesAccountStatus,
  IdentitiesAccountType,
  IdentitiesUserItemRes,
} from '../../apis/types';
import { SortOrder } from 'utils/sort-order/sort';
import { Periods } from 'components/table/table-filters-components/date-picker/date-picker';
import dayjs from 'dayjs';

export interface IdentitiesState {
  accountType?: IdentitiesAccountType;
  accountStatus?: IdentitiesAccountStatus;
  activeSortedField: string;
  activeSortedOrder: SortOrder;
  userGroupsTeam?: Record<string, string>[];
  activityStartDate?: string;
  activityEndDate?: string;
  activityPeriod: string;
  searchQuery: string;
  calendarTab: number;
  repoTableSearchQuery: string;
  memberTableSearchQuery: string;
  editRows: IdentitiesUserItemRes[];
  hoveredRow: any;
}

const initialState: IdentitiesState = {
  accountType: IdentitiesAccountType.total,
  activeSortedField: 'access_count',
  activeSortedOrder: SortOrder.Desc,
  activityPeriod: Periods[2],
  activityStartDate: dayjs(new Date())
    .subtract(1, 'months')
    .format('YYYY-MM-DDTHH:mm:ss'),
  activityEndDate: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
  searchQuery: '',
  calendarTab: 0,
  repoTableSearchQuery: '',
  memberTableSearchQuery: '',
  editRows: [],
  hoveredRow: '',
};

export const identitiesSlice = createSlice({
  name: 'identities',
  initialState,
  reducers: {
    setAccountType: (
      state: IdentitiesState,
      action: PayloadAction<IdentitiesAccountType | undefined>,
    ) => {
      state.accountType = action.payload;
    },
    setAccountStatus: (
      state: IdentitiesState,
      action: PayloadAction<IdentitiesAccountStatus | undefined>,
    ) => {
      state.accountStatus = action.payload;
    },
    setActiveSortedField: (
      state: IdentitiesState,
      action: PayloadAction<string>,
    ) => {
      state.activeSortedField =
        action.payload || initialState.activeSortedField;
    },
    setActiveSortedOrder: (
      state: IdentitiesState,
      action: PayloadAction<SortOrder>,
    ) => {
      state.activeSortedOrder = action.payload;
    },
    setUserGroupsTeams: (
      state: IdentitiesState,
      action: PayloadAction<Record<string, string>[]>,
    ) => {
      state.userGroupsTeam = action.payload;
    },
    setActivityStartDate: (
      state: IdentitiesState,
      action: PayloadAction<string | undefined>,
    ) => {
      state.activityStartDate = action.payload;
    },
    setActivityEndDate: (
      state: IdentitiesState,
      action: PayloadAction<string | undefined>,
    ) => {
      state.activityEndDate = action.payload;
    },
    setActivityPeriod: (
      state: IdentitiesState,
      action: PayloadAction<string>,
    ) => {
      state.activityPeriod = action.payload;
    },
    setSearchQuery: (state: IdentitiesState, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
    setCalendarTab: (state: IdentitiesState, action: PayloadAction<number>) => {
      state.calendarTab = action.payload;
    },
    resetIdentitiesFilters: (state: IdentitiesState) => {
      state.calendarTab = initialState.calendarTab;
      state.searchQuery = initialState.searchQuery;
      state.repoTableSearchQuery = initialState.repoTableSearchQuery;
      state.memberTableSearchQuery = initialState.memberTableSearchQuery;
    },
    setRepoTableSearchQuery: (
      state: IdentitiesState,
      action: PayloadAction<string>,
    ) => {
      state.repoTableSearchQuery = action.payload;
    },
    setMemberTableSearchQuery: (
      state: IdentitiesState,
      action: PayloadAction<string>,
    ) => {
      state.memberTableSearchQuery = action.payload;
    },
    setEditRows: (
      state: IdentitiesState,
      action: PayloadAction<IdentitiesUserItemRes[]>,
    ) => {
      state.editRows = action.payload;
    },
    setHoveredRow: (state: IdentitiesState, action: PayloadAction<any>) => {
      state.hoveredRow = action.payload;
    },
  },
});

export const {
  setAccountType,
  setAccountStatus,
  setActiveSortedField,
  setActiveSortedOrder,
  setUserGroupsTeams,
  setActivityStartDate,
  setActivityEndDate,
  setActivityPeriod,
  setSearchQuery,
  setCalendarTab,
  setRepoTableSearchQuery,
  setMemberTableSearchQuery,
  setEditRows,
  setHoveredRow,
  resetIdentitiesFilters,
} = identitiesSlice.actions;

export const identitiesReducer = identitiesSlice.reducer;

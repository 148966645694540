import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ToastMessage } from 'components/toast/toast-message';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import { useActiveIntegrationSelector } from 'store/modules/connectors/connectors.selector';
import {
  closeModalByNameAction,
  PlainModals,
} from 'store/modules/modals/modals.reducer';
import { useIsPlainModalOpenSelector } from 'store/modules/modals/modals.selector';
import { ModalContainer } from 'components/modals/modal-container/modal-container';

export const ConnectorRemoveModal = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const apiContext = useContext(ApiContext);
  const activeConnection = useActiveIntegrationSelector();
  const [deleteConnection] = apiContext.baseApi?.useDeleteConnectionMutation();

  const isModalOpen = useIsPlainModalOpenSelector(
    PlainModals.CONNECTORS_REMOVE_INTEGRATION_MODAL,
  );

  const handleCloseModal = () =>
    dispatch(
      closeModalByNameAction(PlainModals.CONNECTORS_REMOVE_INTEGRATION_MODAL),
    );

  const removeConnectionHandler = async () => {
    const rawConnectorId = activeConnection.raw_connector_id
      ? activeConnection.raw_connector_id
      : '';
    try {
      await deleteConnection({
        rawConnectorId: encodeURIComponent(encodeURIComponent(rawConnectorId)),
      }).unwrap();
      handleCloseModal();
      // navigate(paths.connectors);
      toast(
        <ToastMessage
          type="success"
          title="Connection was removed successfully"
        />,
      );
    } catch (error) {
      toast(<ToastMessage type="error" title="Oops! Error happened" />);
    }
  };

  return (
    <ModalContainer
      title="Are you sure you want to remove this integration?"
      isOpenModal={isModalOpen}
      closeModalTitle="Cancel"
      confirmModalTitle="Remove"
      handleCloseModal={handleCloseModal}
      handleConfirm={removeConnectionHandler}
    />
  );
};

import { TableColumnProps } from 'components/table/table-component/table-component';

export const activityTableStructure: TableColumnProps[] = [
  {
    id: 'type',
    title: 'Type',
    withSort: true,
    position: 'left',
  },
  {
    id: 'timestamp',
    title: 'Date',
    withSort: true,
    position: 'left',
  },
  {
    id: 'geo_location',
    title: 'Geolocation',
    withSort: true,
    position: 'left',
  },
  {
    id: 'ip',
    title: 'IP Address',
    withSort: true,
    position: 'left',
  },
  {
    id: 'owner',
    title: 'Organization',
    withSort: true,
    position: 'left',
  },
  {
    id: 'repository',
    title: 'Resource',
    withSort: true,
    position: 'left',
  },
  {
    id: 'graph',
    title: 'Commit Ref',
    position: 'left',
  },
];

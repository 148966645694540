import { TableColumnProps } from 'components/table/table-component/table-component';

export const tableStructure: TableColumnProps[] = [
  {
    id: 'account',
    title: 'Account Id',
    position: 'left',
    withSort: true,
  },
  {
    id: 'repository_name',
    title: 'Repository Name',
    position: 'left',
    withSort: true,
  },
  {
    id: 'image_tags',
    title: 'Image Tag',
    position: 'left',
    withSort: true,
  },
  {
    id: 'image_digest',
    title: 'Image Digest',
    position: 'left',
  },
  {
    id: 'image_pushed_at',
    title: 'Image Push Time',
    position: 'left',
    isDate: true,
    withSort: true,
  },
  {
    id: 'options',
    title: 'Download SBOM',
    position: 'left',
  },
];

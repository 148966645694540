import { FilterItem } from 'components/multi-filter/multi-filter';
import { TableColumnProps } from 'components/table/table-component/table-component';

export const SecretTableStructure: TableColumnProps[] = [
  {
    id: 'description',
    title: 'Secret Type',
    withSort: true,
    position: 'left',
  },
  {
    id: 'organization',
    title: 'Organization',
    withSort: true,
    position: 'left',
  },
  {
    id: 'repository',
    title: 'Repository',
    withSort: true,
    position: 'left',
  },
  {
    id: 'commit',
    title: 'SHA',
    position: 'left',
  },
  {
    id: 'user_login',
    title: 'User ID',
    withSort: true,
    position: 'left',
  },
  {
    id: 'email',
    title: 'Email',
    withSort: true,
    position: 'left',
  },
  {
    id: 'created',
    title: 'Commit Date',
    withSort: true,
    position: 'left',
    isDate: true,
  },
  {
    id: 'file',
    title: 'File',
    position: 'left',
  },
  {
    id: 'verified',
    title: 'Verified',
    withSort: true,
    position: 'left',
  },
];

export const SastTableStructure: TableColumnProps[] = [
  {
    id: 'source',
    title: 'Source',
    withSort: true,
    position: 'left',
  },
  {
    id: 'description',
    title: 'Description',
    withSort: true,
    position: 'left',
  },
  {
    id: 'owner',
    title: 'Organization',
    withSort: true,
    position: 'left',
  },
  {
    id: 'repository',
    title: 'Repository',
    withSort: true,
    position: 'left',
  },
  {
    id: 'author_email',
    title: 'User',
    withSort: true,
    position: 'left',
  },
  {
    id: 'severity',
    title: 'Severity',
    withSort: true,
    position: 'left',
  },
  {
    id: 'state',
    title: 'State',
    withSort: true,
    position: 'left',
  },
  {
    id: 'file',
    title: 'File',
    position: 'left',
  },
  {
    id: 'updated_at',
    title: 'Updated',
    withSort: true,
    position: 'left',
    isDate: true,
  },
];

export const iacTableStructure: TableColumnProps[] = [
  {
    id: 'title',
    title: 'Title',
    withSort: true,
    position: 'left',
  },
  {
    id: 'source',
    title: 'Source',
    withSort: true,
    position: 'left',
  },
  {
    id: 'owner',
    title: 'Organization',
    withSort: true,
    position: 'left',
  },
  {
    id: 'repository',
    title: 'Repo',
    withSort: true,
    position: 'left',
  },
  {
    id: 'author_login',
    title: 'User',
    withSort: true,
    position: 'left',
  },
  {
    id: 'severity',
    title: 'Severity',
    withSort: true,
    position: 'left',
  },
  {
    id: 'file_path',
    title: 'File',
    withSort: true,
    position: 'left',
  },
  {
    id: 'causeMetadata',
    title: 'Supporting Data',
    withSort: true,
    position: 'left',
  },
];

export const SecretsFilterList: FilterItem[] = [
  {
    title: 'Status',
    filterId: 'status_filter',
    filterColumns: ['description'],
    props: [
      {
        label: 'Sent',
        value: 'sent',
      },
      {
        label: 'Open',
        value: 'open',
      },
    ],
  },
  {
    title: 'Verified',
    filterId: 'verified',
    filterColumns: ['verified'],
    props: [
      {
        label: 'Is verified',
        value: true,
      },
      {
        label: 'Is not verified',
        value: false,
      },
    ],
  },
];

import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  Radio,
  styled,
} from '@mui/material';
import { TableNamesOfFilters } from 'components/table/table-filters-components/filter-renderer/filter-renderer';

import { Colors } from 'constants/colors';
import { FontSizes } from 'constants/font-sizes';
import { ReactComponent as UncheckedIcon } from 'assets/icons/checkbox.svg';
import { ReactComponent as CheckedIcon } from 'assets/icons/checkbox-ticked.svg';

export const FiltersContainer = styled('div')<{ horizontal?: boolean }>(
  ({ horizontal }) => ({
    display: 'flex',
    flexDirection: horizontal ? 'row' : 'column',
    maxHeight: '300px',
    overflow: 'scroll',
    overflowX: 'hidden',
  }),
);

export const FilterContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  color: Colors.white,
}));

export const FilterTitle = styled('div')(() => ({
  fontSize: FontSizes.fontSize11,
  whiteSpace: 'nowrap',
  border: `1px solid ${Colors.darkCyan}`,
  borderRadius: '4px',
  padding: '4px 6px',
  display: 'flex',
  alignItems: 'center',
  gap: '7px',
  height: '25px',

  '&:hover': {
    borderColor: Colors.white,
  },
}));

export const PopupWrapper = styled('div')<{
  horizontal?: boolean;
  x: number;
  y: number;
  columnName: string;
  isLast: boolean;
}>(({ horizontal, x, y, columnName, isLast }) => {
  const isRightOverflowing = x + 287 > window.innerWidth;
  const isLeftOverflowing = x < 491;
  let right: number | undefined = undefined;
  if (isRightOverflowing) {
    if (isLast || !isLeftOverflowing) right = 0;
    else right = Math.max(x - window.innerWidth, -120);
  }

  return {
    backgroundColor: Colors.white,
    width: 'auto',
    minWidth: '381px',
    height: 'auto',
    position: 'absolute',
    margin: 'auto',
    top:
      columnName === TableNamesOfFilters.ASSETS_REPOS_LIST ||
      columnName === TableNamesOfFilters.TEAMS_LIST
        ? 100
        : columnName === TableNamesOfFilters.IDENTITIES_USERS_LIST
        ? 76
        : 52,
    right: right,
    zIndex: '11',
    borderRadius: '10px',
    padding: '20px 15px',
  };
});

export const FilterSection = styled('div')(() => ({
  // padding: '0 10px 10px',
}));

export const SectionTitle = styled('div')(() => ({
  fontSize: FontSizes.fontSize15,
  fontWeight: 600,
  whiteSpace: 'nowrap',
  margin: '0 0 10px 0',
  textAlign: 'start',
  color: `${Colors.blackPearl}80`,
}));

export const SectionValues = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '6px',
}));

export const StyledFormControl = styled(FormControl)(() => ({
  paddingLeft: '0',
}));

export const StyledFormControlLabel = styled(FormControlLabel)(
  ({ checked }) => ({
    '& .MuiFormControlLabel-label': {
      fontSize: FontSizes.fontSize14,
      color: checked ? Colors.binge : Colors.blackPearl,
    },
  }),
);

export const StyledCheckbox = styled((props: CheckboxProps) => (
  <Checkbox {...props} icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} />
))({});

export const StyledRadio = styled(Radio)(() => ({
  color: Colors.binge,
  padding: '3px 9px',

  '&.Mui-checked': {
    color: Colors.binge,

    '& .MuiSvgIcon-root': {
      color: Colors.binge,
    },
  },

  '& .MuiSvgIcon-root': {
    fontSize: '16px',
    color: Colors.blackPearl,
  },
}));

export const FilterStyledRadio = styled(Radio)(() => ({
  color: Colors.binge,

  '&.Mui-checked': {
    color: Colors.binge,

    '& .MuiSvgIcon-root': {
      color: Colors.binge,
    },
  },

  '& .MuiSvgIcon-root': {
    fontSize: '16px',
    color: Colors.darkCyan,
  },
}));

import React from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';

import { FilterChip } from './filter-chip';
import {
  resetSortState,
  setApplicableFilters,
  setFilterChips,
  setHighlightColumns,
} from 'store/modules/selected-column/selected-column.reducer';
import { Colors } from 'constants/colors';
import { useSelectedColumnSelector } from 'store/modules/selected-column/selected-column.selector';

const fullFilterMapping: Record<string, string> = {
  internal_account: 'identity_status',
  external_account: 'identity_status',
  unknown: 'identity_status',
};

interface props {
  tableId: string;
}

export const FilterChips = ({ tableId }: props) => {
  const dispatch = useDispatch();
  const { applicableFilters, tableFilterChips, highlightColumns } =
    useSelectedColumnSelector(tableId);
  const { sorting_field, sorting_order, filterTitles, ...filterChips } =
    tableFilterChips;
  const removeFilter = (colId: string, column: string, sort?: boolean) => {
    const currentChips = Object.assign(
      { ...filterChips },
      { filterTitles, sorting_field, sorting_order },
    );
    if (!sort) {
      if (typeof filterTitles === 'object' && filterTitles?.length) {
        const currentTitles = filterTitles.filter((item) => item?.id !== colId);
        currentChips.filterTitles = currentTitles;
      }
      delete currentChips[colId];
    }
    if (sort) {
      delete currentChips.sorting_field;
      delete currentChips.sorting_order;
      dispatch(resetSortState({ table: tableId }));
    }
    dispatch(
      setFilterChips({
        table: tableId,
        tableFilterChips: currentChips,
      }),
    );
    if (
      typeof filterTitles === 'object' &&
      filterTitles?.length &&
      filterTitles?.filter((item) => item?.column === column)?.length === 1
    ) {
      const currentColumns = highlightColumns.filter(
        (item) => item?.id !== column,
      );
      if (!sort && column !== sorting_field)
        dispatch(
          setHighlightColumns({
            table: tableId,
            highlightColumns: [...currentColumns],
          }),
        );
    } else if (sort) {
      const currentColumns = highlightColumns.filter(
        (item) => item?.id !== column,
      );
      dispatch(
        setHighlightColumns({
          table: tableId,
          highlightColumns: [...currentColumns],
        }),
      );
    }
    if (!sort) {
      const applyFilter = { ...applicableFilters };
      delete applyFilter[colId];
      Object.keys(applyFilter).forEach((id) => {
        if (fullFilterMapping[id] === colId) {
          delete applyFilter[id];
        }
      });
      dispatch(
        setApplicableFilters({
          table: tableId,
          applicableFilters: { ...applyFilter },
        }),
      );
    }
  };

  return (
    <>
      {sorting_field ? (
        <Box
          sx={{
            borderRight: `1px solid ${Colors.chipBorder}`,
          }}
        >
          <FilterChip
            filterName={'Sort By'}
            sortedColumn={
              highlightColumns.find((column) => column.id === sorting_field)
                ?.title || ''
            }
            filterValue={`${
              sorting_order === 'asc' ? 'Ascending' : 'Descending'
            }`}
            removeFilter={() =>
              removeFilter(
                sorting_field.toString(),
                sorting_field.toString(),
                true,
              )
            }
          />
        </Box>
      ) : null}

      {typeof filterTitles === 'object' && !!filterTitles?.length && (
        <Box
          sx={{
            borderRight: `1px solid ${Colors.chipBorder}`,
            display: 'flex',
          }}
        >
          {filterTitles.map((col) => (
            <FilterChip
              key={col.id}
              filterName={col.title}
              filterValue={filterChips[col.id]?.toString()}
              removeFilter={() => removeFilter(col.id, col.column)}
            />
          ))}
        </Box>
      )}
    </>
  );
};

import { useOSSLanguagesSelector } from 'store/modules/assets/assets.selector';

export const OpenSourceFilterList = () => {
  const languages = useOSSLanguagesSelector();

  const languageProps = languages?.map((lang: any) => {
    return { label: lang ? lang : 'None', value: lang };
  });

  return [
    {
      title: 'Advisories',
      filterId: 'advisories',
      filterColumns: ['advisories'],
      props: [
        {
          label: 'True',
          value: true,
        },
        {
          label: 'False',
          value: false,
        },
      ],
    },
    {
      title: 'Health Rating',
      filterId: 'lte_total_score',
      filterColumns: ['healthRating'],
      props: [
        {
          label: 'Poor',
          value: '40',
        },
        {
          label: 'Moderate',
          value: '67',
        },
        {
          label: 'Good',
          value: '100',
        },
      ],
    },
    ...(languageProps.length
      ? [
          {
            title: 'Language',
            filterId: 'language',
            filterColumns: ['language'],
            props: languageProps,
          },
        ]
      : []),
    {
      title: 'License',
      filterId: 'license',
      filterColumns: ['license'],
      props: [
        {
          label: 'True',
          value: true,
        },
        {
          label: 'False',
          value: false,
        },
      ],
    },
  ];
};

import { useKeycloak } from '@react-keycloak/web';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AnyNaptrRecord } from 'dns';
import moment from 'moment';
import { IntegrationItem } from 'pages/connectors/types';
import { Filter } from '../modules/filters/filters.reducer';
import {
  transformExternalUsersResponse,
  transformHighRiskOSSResponse,
  transformInactiveIdentitiesResponse,
  transformSelfApprovedPullRequestsResponse,
  transformStaleRepositoriesResponse,
  transformUnprotectedDefaultBranchesResponse,
} from './chart-helpers';
import {
  transDependencyGraphDataResponse,
  transformErrorResponse,
  transformIdentitiesUsersResponse,
  transformRepositoriesResponse,
} from './helpers';
import {
  AssetsDetailsActivityRes,
  AssetsDetailsBranchesObjRes,
  AssetsDetailsObjRes,
  AssetsDetailsPipelineRes,
  AssetsPipelineRunsDetailsRes,
  AssetsRepositoriesObj,
  AssetsRepositoriesObjsRes,
  BranchProtectionObjRes,
  ChartData,
  ComplianceOverviewDataRes,
  CompliancePolicyDetailsRes,
  DetectionRes,
  ExposedRepositoriesObjRes,
  ExternalCodeFiltersProps,
  ExternalPackagesObjRes,
  GeneralResponseItem,
  HealthOveriewChartItemRes,
  IAMTeamMappingsData,
  IdentitiesUserAccessObjRes,
  IdentitiesUserActivityObjRes,
  IdentitiesUserDetails,
  IdentitiesUserObj,
  IdentitiesUserObjRes,
  IdentitiesUserPipelineActivityObjRes,
  IdentityAuditActivityListRes,
  OpenSourceDependenciesItemRes,
  OpenSourceDependenciesObjRes,
  PolicyCriteriaItem,
  RecommendationsOverviewDataRes,
  RecommendationsStatusRes,
  ReportObjRes,
  RepositoriesContributorObjRes,
  RepositoriesTeamsObjRes,
  SecretDetectionFiltersProps,
  SecretDetectionItem,
  SecretDetectionObjRes,
  TeamAssetItemRes,
  TeamDetailsRes,
  TeamMemberItemRes,
  TeamsObjRes,
} from './types';

interface RawNodeDatum {
  name: string;
  attributes?: Record<string, string | number | boolean>;
  children?: RawNodeDatum[];
}

interface FiltersProps {
  critical?: boolean;
  status?: string;
  source?: string;
  only_hidden?: boolean;
  only_watched?: boolean;
  only_production?: boolean;
  highest_severity?: any;
  private?: any;
  visibility?: string;
  max_compliance_score?: string;
}

// const encodeParams = (params: any) => {
//   return Object.keys(params)
//     .filter((key) => params[key])
//     .reduce((acc: any, key) => {
//       console.log(params[key]);
//       acc[key] = encodeURIComponent(params[key]);
//       return acc;
//     }, {});
// };

export const generateBaseApi = (
  getAccessTokenSilently: () => Promise<string>,
) =>
  createApi({
    reducerPath: 'baseApi',
    baseQuery: fetchBaseQuery({
      baseUrl: process.env.REACT_APP_BASE_URL,
      prepareHeaders: async (headers) => {
        const token = await getAccessTokenSilently();
        if (token) {
          headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
      },
      // paramsSerializer: (params) => {
      //   const encodedParams = encodeParams(params);
      //   return new URLSearchParams(encodedParams).toString();
      // },
    }),
    tagTypes: [
      'CurrentConnections',
      'WebhookTest',
      'GetIdentitiesUserList',
      'GetSastScanData',
      'GetIacScanData',
      'GetTrivyScanData',
      'GetArtifactoryRepositoriesList',
      'GetRepositoriesList',
      'GetPolicies',
      'GetPolicyQuestions',
      'GetPolicyConversation',
      'GetBranchesList',
      'GetTeamsList',
      'GetTeamChart',
      'GetTeamDetailsObj',
      'GetDetectionList',
      'GetUsers',
      'GetIdentitiesUserDetails',
      'GetAssetsDetailsObj',
      'GetRepositoriesExternalList',
      'RepositoriesExternalTree',
      'GetOpensourceDependenciesDetails',
      'GetDetectedSecretsList',
      'GetOnboardingChecklistStatus',
      'GetPolicyCriteriaByName',
      'GetPolicyTrendDataByName',
      'GetPolicyByName',
      'GetHealthOverviewChartData',
      'GetSecretByType',
      'GetSettingsInfo',
      'GetExposedRepositories',
      'GetContributors',
      'GetIdentityUnusedAccess',
      'GetTeamRepoRecommendation',
      'GetTeamMemberRecommendation',
      'GetPolicySchedule',
      'GetRepositoryContributors',
      'GetRepositoryTeams',
      'GetJITRequests',
      'GetPersonalJITRequests',
      'GetRepositoryContributors',
      'GetTokens',
      'GetQuerySuggestions',
      'GetGlobalSearchData',
      'GetDashboardTiles',
      'GetInsightsGeolocation',
      'GetArtifactsData',
      'GetPRSecretScanData',
      'GetAssetsJFrogDetailsObj',
    ],
    endpoints: (builder) => ({
      getInactiveIdentities: builder.query<ChartData, void>({
        query: () => ({
          url: '/users/inactive/chart',
        }),
        transformResponse: (response: Record<string, string>) => {
          return transformInactiveIdentitiesResponse(response);
        },
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getHighRiskPackages: builder.query<ChartData, void>({
        query: () => ({
          url: '/repositories/external/high_chart',
        }),
        transformResponse: (response: Record<string, string>) => {
          return transformHighRiskOSSResponse(response);
        },
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getAllDashboardDetections: builder.query<any[], void>({
        query: () => ({
          url: '/rules/get_all_detections',
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getDashboardTiles: builder.query<any[], void>({
        query: () => ({
          url: '/dashboard/tiles/settings',
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetDashboardTiles'],
      }),
      updateDashboardTiles: builder.mutation<any, any>({
        query: (dashboardTiles) => ({
          url: `/dashboard/tiles/settings`,
          method: 'POST',
          body: dashboardTiles,
        }),
        invalidatesTags: ['GetDashboardTiles'],
      }),
      getAllPolicyDetections: builder.query<any[], void>({
        query: () => ({
          url: '/dashboard/tiles/policy',
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getIdentityOverview: builder.query<any, void>({
        query: () => ({
          url: '/dashboard/tiles/inventory-identity',
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getAssetOverview: builder.query<any, void>({
        query: () => ({
          url: '/dashboard/tiles/inventory-repo',
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getAllPolicyDetectionsBySeverity: builder.query<any, boolean>({
        query: (isCustom) => ({
          url: `dashboard/tiles/policy_detections?is_custom=${isCustom}`,
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getIdentitiesChart: builder.query<any[], void>({
        query: () => ({
          url: '/dashboard/tiles/chart/identities',
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getIdentitiesOverprivilegeChart: builder.query<any[], void>({
        query: () => ({
          url: '/dashboard/tiles/chart/identities/overprivilege',
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getAssetsChart: builder.query<any[], void>({
        query: () => ({
          url: '/dashboard/tiles/chart/assets',
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getTeamsChart: builder.query<any[], void>({
        query: () => ({
          url: '/dashboard/tiles/chart/teams',
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getRecommendationsChart: builder.query<any[], void>({
        query: () => ({
          url: '/dashboard/tiles/chart/recommendations',
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getUserInfo: builder.query<any, void>({
        query: () => ({
          url: '/users/self',
          headers: {
            'Cache-Control': 'no-cache',
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getUnprotectedDefaultBranches: builder.query<ChartData, void>({
        query: () => ({
          url: '/analytics/charts/default-branches',
        }),
        transformResponse: (response: Record<string, string>) => {
          return transformUnprotectedDefaultBranchesResponse(response);
        },
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getExternalUsers: builder.query<ChartData, void>({
        query: () => ({
          url: '/users/external/chart',
        }),
        transformResponse: (response: Record<string, string>) => {
          return transformExternalUsersResponse(response);
        },
      }),
      getSelfApprovedPullRequests: builder.query<ChartData, void>({
        query: () => ({
          url: '/analytics/pr_repo_types/test',
        }),
        transformResponse: (response: Record<string, string>) => {
          return transformSelfApprovedPullRequestsResponse(response);
        },
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getAllAvailableIntegrations: builder.query<IntegrationItem[], void>({
        query: () => '/connectors',
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getAllCurrentConnections: builder.query<IntegrationItem[], void>({
        query: () => '/connectors/connections',
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['CurrentConnections'],
      }),
      testWebhookConnection: builder.query<any, any>({
        query: (connector_id: any) => ({
          url: '/connectors/connections/webhook/test',
          method: 'POST',
          body: { id: connector_id },
        }),
        providesTags: ['WebhookTest'],
      }),
      editConnection: builder.mutation<IntegrationItem, IntegrationItem>({
        query: (connection) => ({
          url: `/connectors/connections/${connection.connector_id}`,
          method: 'PUT',
          body: { connection },
        }),
        invalidatesTags: [
          'CurrentConnections',
          'GetOnboardingChecklistStatus',
          'GetPolicyCriteriaByName',
        ],
      }),
      createConnection: builder.mutation<
        { status: string; message: string },
        IntegrationItem
      >({
        query: (connection) => ({
          url: `/connectors/connections/${connection.connector_id}`,
          method: 'POST',
          body: { ...connection },
        }),
        invalidatesTags: [
          'CurrentConnections',
          'GetOnboardingChecklistStatus',
          'GetPolicyCriteriaByName',
        ],
      }),
      deleteConnection: builder.mutation<
        { status: string; message: string },
        { rawConnectorId: string }
      >({
        query: ({ rawConnectorId }) => ({
          url: `/connectors/connections/${rawConnectorId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['CurrentConnections', 'GetPolicyCriteriaByName'],
      }),
      getWebHookUrl: builder.query<any, { connectorId: string }>({
        query: ({ connectorId }) => `/connectors/connections/${connectorId}`,
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getPolicyByName: builder.query<
        any,
        { policyName: string; policyType: any }
      >({
        query: ({ policyName, policyType }) => ({
          url: `/rules/policy/${encodeURIComponent(
            encodeURIComponent(policyName),
          )}?policy_type=${policyType}`,
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetPolicyByName'],
      }),
      getTestPolicyData: builder.query<
        any,
        { policyName?: string; userName?: string; matchPredicate?: any }
      >({
        query: ({ policyName, userName, matchPredicate }) => {
          return {
            url:
              userName && userName.length > 0 && !matchPredicate
                ? `/rules/test-policy/${encodeURIComponent(
                    policyName!,
                  )}?user_id=${encodeURIComponent(userName)}`
                : `/rules/test-policy/${encodeURIComponent(policyName!)}`,
            params: {
              match_predicate: matchPredicate,
            },
          };
        },
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      createCustomPolicyV1: builder.mutation<void, any>({
        query: (body) => ({
          url: '/rules/policy/v1',
          method: 'POST',
          body,
        }),
        invalidatesTags: ['GetPolicies'],
      }),
      createCustomPolicyV2: builder.mutation<void, any>({
        query: (body) => ({
          url: '/rules/policy/v2',
          method: 'POST',
          body,
        }),
        invalidatesTags: ['GetPolicies'],
      }),
      generateToken: builder.query<any, void>({
        query: () => ({
          url: '/connectors/connections/compass',
          method: 'GET',
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getTokens: builder.mutation<any, void>({
        query: () => ({
          url: '/admin/get-compass-token',
          method: 'POST',
        }),
      }),
      getPolicies: builder.query<
        any,
        {
          sortBy?: string;
          tableFilters?: any;
        }
      >({
        query: ({ sortBy, tableFilters }) => ({
          url: '/rules/policy',
          params: {
            sort_by: sortBy,
            activated: tableFilters?.activated,
            entity_type: tableFilters?.entity_type,
            frequency: tableFilters?.frequency,
            severity: tableFilters?.severity,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetPolicies'],
      }),
      getPolicyQuestions: builder.query<any, any>({
        query: (policyName) => ({
          url: `/openai/suggestions/${encodeURIComponent(policyName)}`,
        }),
        providesTags: ['GetPolicyQuestions'],
      }),
      getPolicyConversation: builder.query<any, any>({
        query: (payload) => ({
          url: `/openai/conversations`,
          method: 'POST',
          body: payload,
        }),
        providesTags: ['GetPolicyConversation'],
      }),
      getQuerySuggestions: builder.query<any, string>({
        query: (prompt) => ({
          url: `/openai/global-search-query-suggestions?query=${prompt}`,
        }),
        providesTags: ['GetQuerySuggestions'],
      }),
      getGlobalSearchData: builder.query<any, any>({
        query: (prompt) => ({
          url: `/openai/search`,
          method: 'POST',
          body: { prompt: prompt },
        }),
        providesTags: ['GetGlobalSearchData'],
      }),
      getUserLog: builder.query<any, void>({
        query: () => ({
          url: `/admin/user-log`,
        }),
      }),
      togglePolicy: builder.mutation<any, string>({
        query: (policyName) => ({
          url: `/rules/policy/change-status/${encodeURIComponent(policyName)}`,
          method: 'POST',
        }),
        invalidatesTags: [
          'GetPolicies',
          'GetOnboardingChecklistStatus',
          'GetPolicyCriteriaByName',
        ],
      }),
      editCustomPolicyV1: builder.mutation<void, any>({
        query: (body) => ({
          url: `/rules/policy/v1/${body.name}`,
          method: 'PUT',
          body,
        }),
        invalidatesTags: [
          'GetPolicies',
          'GetOnboardingChecklistStatus',
          'GetPolicyCriteriaByName',
        ],
      }),
      editCustomPolicyV2: builder.mutation<void, any>({
        query: (body) => ({
          url: `/rules/policy/v2/${body.name}`,
          method: 'PUT',
          body,
        }),
        invalidatesTags: [
          'GetPolicies',
          'GetOnboardingChecklistStatus',
          'GetPolicyCriteriaByName',
        ],
      }),
      removePolicy: builder.mutation<void, string>({
        query: (email) => ({
          url: `/rules/policy/${encodeURIComponent(email)}`,
          method: 'DELETE',
        }),
      }),
      getPolicySchedule: builder.query<any, string>({
        query: (policyName) => ({
          url: `/scheduler/get-schedule/${encodeURIComponent(
            encodeURIComponent(policyName),
          )}`,
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetPolicySchedule'],
      }),
      updatePolicySchedule: builder.mutation<void, any>({
        query: ({ policy, schedule, body }) => ({
          url: `/scheduler/modify-schedule/${policy}/${schedule}`,
          method: 'POST',
          body,
        }),
        invalidatesTags: ['GetPolicySchedule'],
      }),
      createPolicySchedule: builder.mutation<void, any>({
        query: ({ policy, body }) => ({
          url: `/scheduler/create-schedule/${policy.name}`,
          method: 'POST',
          body,
        }),
        invalidatesTags: [],
      }),
      removePolicySchedule: builder.mutation<void, any>({
        query: ({ policy, schedule }) => ({
          url: `/scheduler/remove-schedule/${policy}/${schedule}`,
          method: 'DELETE',
        }),
      }),
      getPolicyCriteriaByName: builder.query<PolicyCriteriaItem[], string>({
        query: (policyName) => ({
          url: `/rules/policy/criteria/${encodeURIComponent(
            encodeURIComponent(policyName),
          )}`,
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetPolicyCriteriaByName'],
      }),
      getPolicyTrendDataByName: builder.query<any, any>({
        query: ({ policyName, frequency }) => ({
          url: `/rules/policy/timeseries/${encodeURIComponent(
            encodeURIComponent(policyName),
          )}?time_filter=${frequency}`,
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetPolicyTrendDataByName'],
      }),
      getBranchProtectionData: builder.query<
        BranchProtectionObjRes,
        {
          pageNum: number;
          sortBy?: string;
          tableFilters: Filter;
          searchQuery: string;
          itemsPerPage: number;
        }
      >({
        query: ({
          pageNum,
          sortBy,
          tableFilters: {
            only_environment,
            only_important,
            only_protected,
            only_workflow,
            only_default,
          },
          searchQuery,
          itemsPerPage,
        }) => ({
          url: '/analytics/branch_protection',
          params: {
            items_page: itemsPerPage,
            page: pageNum,
            sort_by: sortBy,
            only_environment,
            only_important,
            only_protected,
            only_workflow,
            only_default,
            query: searchQuery,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetBranchesList'],
      }),
      getTeamsList: builder.query<
        TeamsObjRes,
        {
          pageNum: number;
          sortBy?: string;
          tableFilters?: Filter;
          searchQuery?: string;
          itemsPerPage: number;
        }
      >({
        query: ({
          pageNum,
          sortBy,
          tableFilters,
          searchQuery,
          itemsPerPage,
        }) => ({
          url: '/users/teams',
          params: {
            items_page: itemsPerPage,
            page: pageNum,
            sort_by: sortBy,
            is_team_status_active: tableFilters?.is_team_status_active,
            only_watched: tableFilters?.only_watched,
            only_hidden: tableFilters?.only_hidden,
            highest_severity: tableFilters?.highest_severity,
            query: searchQuery,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetTeamsList'],
      }),
      getTeamVulnChart: builder.query<any, string>({
        query: (teamId) => `/users/team/vuln/${teamId}`,
      }),
      getTeamChart: builder.query<any, string>({
        query: (teamId) => `/users/team/chart/${teamId}`,
        providesTags: ['GetTeamChart'],
      }),
      getTeamDetails: builder.query<TeamDetailsRes, any>({
        query: ({ teamId, source }) =>
          `/users/teams/details/${encodeURIComponent(teamId)}?source=${source}`,
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetTeamDetailsObj'],
      }),
      getTeamReposList: builder.query<
        TeamAssetItemRes,
        {
          id: string;
          pageNum: number;
          sortBy?: string;
          searchQuery: string;
          itemsPerPage: number;
          source: string;
          tableFilters?: Filter;
        }
      >({
        query: ({
          id,
          pageNum,
          sortBy,
          searchQuery,
          itemsPerPage,
          source,
          tableFilters,
        }) => ({
          url: `/users/teams/assets/${encodeURIComponent(id)}`,
          params: {
            page: pageNum,
            sort_by: sortBy,
            query: searchQuery,
            items_page: itemsPerPage,
            source: source,
            highest_severity: tableFilters?.highest_severity,
            is_repo_owner: tableFilters?.isRepoOwner,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getTeamMembersList: builder.query<
        TeamMemberItemRes,
        {
          id: string;
          pageNum: number;
          sortBy?: string;
          itemsPerPage: number;
          searchQuery: string;
          source: string;
          tableFilters?: Filter;
        }
      >({
        query: ({
          id,
          pageNum,
          sortBy,
          itemsPerPage,
          searchQuery,
          source,
          tableFilters,
        }) => ({
          url: `/users/teams/members/${encodeURIComponent(id)}`,
          params: {
            page: pageNum,
            sort_by: sortBy,
            items_page: itemsPerPage,
            query: searchQuery,
            source: source,
            highest_severity: tableFilters?.highest_severity,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getIAMTeamMappings: builder.query<
        IAMTeamMappingsData,
        {
          id: string;
          pageNum: number;
          itemsPerPage: number;
          source: string;
        }
      >({
        query: ({ id, pageNum, itemsPerPage, source }) => ({
          url: `/users/teams/mapping/${encodeURIComponent(id)}`,
          params: { page: pageNum, items_page: itemsPerPage, source: source },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      sendRiskAssessmentReportOnEmail: builder.mutation<void, string>({
        query: (email) => ({
          url: `/analytics/reports/risk_assessment/${email}`,
          method: 'GET',
        }),
      }),
      getDetectionsList: builder.query<
        DetectionRes,
        {
          pageNum: number;
          sortBy?: string;
          searchQuery?: string;
          tableFilters: {
            status?: string;
            severity_filter?: string;
          };
          itemsPerPage: number;
        }
      >({
        query: ({
          pageNum,
          sortBy,
          searchQuery,
          tableFilters,
          itemsPerPage,
        }) => {
          const params: Record<string, any> = {
            items_page: itemsPerPage,
            page: pageNum,
            sort_by: sortBy,
            query: searchQuery,
          };
          if (tableFilters?.status !== 'all') {
            params.status = tableFilters?.status;
          }
          if (tableFilters?.severity_filter !== 'all') {
            params.severity_filter = tableFilters?.severity_filter;
          }
          return {
            url: '/rules/detection',
            method: 'GET',
            params,
          };
        },
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetDetectionList'],
      }),
      changeDetectionStatus: builder.mutation<
        void,
        { detectionId: string; status: string }
      >({
        query: ({ detectionId, status }) => ({
          url: `/rules/detection/status/${detectionId}`,
          method: 'PUT',
          params: { status },
        }),
        invalidatesTags: ['GetDetectionList'],
      }),
      getUsers: builder.query<any, { searchQuery?: string }>({
        query: ({ searchQuery }) => ({
          url: '/admin/users',
          params: searchQuery ? { query: searchQuery } : {},
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetUsers'],
      }),
      inviteUser: builder.mutation<GeneralResponseItem, any>({
        query: (payload) => ({
          url: '/admin/users/invite',
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['GetUsers', 'GetOnboardingChecklistStatus'],
      }),
      deleteUser: builder.mutation<void, any>({
        query: (userId: string) => ({
          url: `/admin/users/${encodeURIComponent(userId)}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['GetUsers'],
      }),
      blockOrUnblockUser: builder.mutation<void, string>({
        query: (userId) => ({
          url: `/admin/users/block/${userId}`,
          method: 'PATCH',
        }),
        invalidatesTags: ['GetUsers'],
      }),
      resetPassword: builder.mutation<void, string>({
        query: (email) => ({
          url: `admin/users/password/reset?email=${email}`,
          method: 'POST',
        }),
        invalidatesTags: ['GetUsers'],
      }),
      getIdentitiesUserAccessList: builder.query<
        IdentitiesUserAccessObjRes,
        {
          id: string;
          pageNum: number;
          sortBy?: string;
          tableFilters: Filter;
          searchQuery?: string;
          itemsPerPage?: number;
          source?: any;
        }
      >({
        query: ({
          id,
          pageNum,
          sortBy,
          tableFilters,
          searchQuery,
          itemsPerPage,
          source,
        }) => ({
          url: `/users/assets/${encodeURIComponent(id)}`,
          params: {
            page: pageNum,
            items_page: itemsPerPage || 40,
            sort_by: sortBy,
            query: searchQuery,
            highest_permission: tableFilters?.highest_permission,
            permission_used: tableFilters?.permission_used,
            is_critical: tableFilters?.is_critical,
            is_accessed: tableFilters?.is_accessed,
            permissions: tableFilters?.permissions,
            only_overprivilege: tableFilters?.only_overprivilege,
            source: source,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getIdentityFolders: builder.query<
        any,
        {
          id: string;
          pageNum: number;
          sortBy?: string;
          searchQuery?: string;
          itemsPerPage?: number;
        }
      >({
        query: ({ id, pageNum, sortBy, searchQuery, itemsPerPage }) => ({
          url: `/users/${encodeURIComponent(id)}/folders`,
          params: {
            query: searchQuery,
            items_page: itemsPerPage,
            page: pageNum,
            sort_by: sortBy,
          },
        }),
      }),
      getIdentityArtifacts: builder.query<
        any,
        {
          id: string;
          pageNum: number;
          sortBy?: string;
          searchQuery?: string;
          itemsPerPage?: number;
        }
      >({
        query: ({ id, pageNum, sortBy, searchQuery, itemsPerPage }) => ({
          url: `/users/${encodeURIComponent(id)}/artifacts`,
          params: {
            query: searchQuery,
            items_page: itemsPerPage,
            page: pageNum,
            sort_by: sortBy,
          },
        }),
      }),
      getIdentityJFrogAssets: builder.query<
        any,
        {
          id: string;
          pageNum: number;
          sortBy?: string;
          searchQuery?: string;
          itemsPerPage?: number;
          source?: string;
        }
      >({
        query: ({
          id,
          pageNum,
          sortBy,
          searchQuery,
          itemsPerPage,
          source,
        }) => ({
          url: `/users/artifactory-assets/${encodeURIComponent(id)}`,
          params: {
            query: searchQuery,
            items_page: itemsPerPage,
            page: pageNum,
            sort_by: sortBy,
            source: source,
          },
        }),
      }),
      getAssetsJFrogDetailsObj: builder.query<any, any>({
        query: ({ assetId, source }) =>
          `/repositories/artifactory/${encodeURIComponent(
            assetId,
          )}?source=${encodeURIComponent(source)}`,
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetAssetsJFrogDetailsObj'],
      }),
      getIdentityJFrogActivity: builder.query<
        any,
        {
          id: string;
          pageNum: number;
          sortBy?: string;
          searchQuery?: string;
          itemsPerPage?: number;
        }
      >({
        query: ({ id, pageNum, sortBy, searchQuery, itemsPerPage }) => ({
          url: `/users/artifactory-activity/${encodeURIComponent(id)}`,
          params: {
            query: searchQuery,
            items_page: itemsPerPage,
            page: pageNum,
            sort_by: sortBy,
          },
        }),
      }),
      getIdentitiesUserDetails: builder.query<IdentitiesUserDetails, any>({
        query: ({ userId, source }) => {
          return `/users/${encodeURIComponent(
            userId,
          )}?source=${encodeURIComponent(source)}`;
        },
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetIdentitiesUserDetails'],
      }),
      getUserInsights: builder.query<any, any>({
        query: ({ userId, source }) => {
          return `/users/insights/${encodeURIComponent(
            userId,
          )}?source=${encodeURIComponent(source)}`;
        },
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getAssetInsights: builder.query<any, any>({
        query: ({ repo_slug, source }) => {
          return `/repositories/insights/${encodeURIComponent(
            repo_slug,
          )}?source=${encodeURIComponent(source)}`;
        },
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getTeamInsights: builder.query<any, any>({
        query: ({ team_slug, source }) => {
          return `/users/teams/insights/${encodeURIComponent(
            team_slug,
          )}?source=${encodeURIComponent(source)}`;
        },
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getOpensourceDependenciesDetails: builder.query<
        OpenSourceDependenciesItemRes,
        string
      >({
        query: (userId) => `/repositories/external/list/${userId}`,
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetOpensourceDependenciesDetails'],
      }),
      getIdentitiesUserActivityList: builder.query<
        IdentitiesUserActivityObjRes,
        {
          id: string;
          pageNum: number;
          startDate?: string;
          endDate?: string;
          searchQuery: string;
          itemsPerPage: number;
          sortBy?: string;
          source?: any;
        }
      >({
        query: ({
          id,
          pageNum,
          startDate,
          endDate,
          searchQuery,
          itemsPerPage,
          source,
          sortBy,
        }) => ({
          url: `/users/activity/${encodeURIComponent(id)}`,
          params: {
            page: pageNum,
            items_page: itemsPerPage,
            start_date: startDate,
            end_date: endDate,
            query: searchQuery,
            source: source,
            sort_by: sortBy,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getIdentitiesUserPipelineActivityList: builder.query<
        IdentitiesUserPipelineActivityObjRes,
        {
          id: string;
          pageNum: number;
          startDate?: string;
          endDate?: string;
          itemsPerPage: number;
          source?: any;
          sortBy?: string;
          tableFilters?: any;
        }
      >({
        query: ({
          id,
          pageNum,
          startDate,
          endDate,
          itemsPerPage,
          source,
          sortBy,
          tableFilters,
        }) => ({
          url: `/users/activity/pipeline/${encodeURIComponent(id)}`,
          params: {
            page: pageNum,
            items_page: itemsPerPage,
            start_date: startDate,
            end_date: endDate,
            source: source,
            sort_by: sortBy,
            status: tableFilters?.status,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getIdentitiesUserAlertList: builder.query<
        any,
        {
          id: string;
          sortBy: string;
          source?: any;
          tableFilters?: any;
          pageNum: number;
          itemsPerPage: number;
        }
      >({
        query: ({
          id,
          sortBy,
          source,
          tableFilters,
          pageNum,
          itemsPerPage,
        }) => ({
          url: `/users/alerts/${encodeURIComponent(id)}`,
          params: {
            sort_by: sortBy,
            source: source,
            severity_filter: tableFilters?.severity_filter,
            status_filter: tableFilters?.status_filter,
            page: pageNum,
            items_page: itemsPerPage,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getIdentitiesSecretsRiskList: builder.query<
        any,
        {
          id: string;
          type: string;
          sortBy: string;
          searchQuery: string;
          pageNum: number;
          tableFilters?: SecretDetectionFiltersProps;
          itemsPerPage: number;
          source?: any;
        }
      >({
        query: ({
          id,
          type,
          sortBy,
          searchQuery,
          pageNum,
          tableFilters,
          itemsPerPage,
          source,
        }) => ({
          url: `/users/alerts/${encodeURIComponent(type)}/${encodeURIComponent(
            id,
          )}`,
          params: {
            sort_by: sortBy,
            query: searchQuery,
            page: pageNum,
            items_page: itemsPerPage,
            status_filter: tableFilters?.status_filter,
            verified: tableFilters?.verified,
            source: source,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getIdentitiesOSSRiskList: builder.query<
        DetectionRes,
        {
          id: string;
          sortBy: string;
          searchQuery: string;
          pageNum: number;
          tableFilters?: any;
          itemsPerPage: number;
          source?: any;
        }
      >({
        query: ({
          id,
          sortBy,
          searchQuery,
          pageNum,
          tableFilters,
          itemsPerPage,
          source,
        }) => ({
          url: `/users/alerts/oss/${encodeURIComponent(id)}`,
          params: {
            sort_by: sortBy,
            query: searchQuery,
            page: pageNum,
            items_page: itemsPerPage,
            status: tableFilters?.status,
            source: source,
            severity: tableFilters?.severity,
            isTopLevel: tableFilters?.isTopLevel,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getIdentityUnusedAccess: builder.query<any, any>({
        query: ({ userId, sortBy, tableFilters, source }) => ({
          url: `users/unused-access/${encodeURIComponent(
            userId,
          )}?source=${encodeURIComponent(source)}`,
          params: {
            sort_by: sortBy,
            is_critical: tableFilters?.is_critical,
            permission: tableFilters?.permission,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetIdentityUnusedAccess'],
      }),
      getRepositoriesContributorsList: builder.query<
        RepositoriesContributorObjRes,
        {
          pageNum: number;
          tableFilters: Filter;
          searchQuery: string;
          sortBy: string;
          id: string;
          itemsPerPage: number;
          source: string;
        }
      >({
        query: ({
          id,
          tableFilters: { only_owners, only_overprivilege },
          searchQuery,
          pageNum,
          itemsPerPage,
          sortBy,
          source,
        }) => ({
          url: `/repositories/contributors/${encodeURIComponent(id!)}`,
          params: {
            items_page: itemsPerPage,
            page: pageNum,
            query: searchQuery,
            only_owners,
            only_overprivilege,
            sort_by: sortBy,
            source,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetRepositoryContributors'],
      }),
      getArtifactsData: builder.query<any, any>({
        query: ({ id, searchQuery, pageNum, itemsPerPage, sortBy }) => ({
          url: `/repositories/${id}/artifacts`,
          params: {
            items_page: itemsPerPage,
            page: pageNum,
            query: searchQuery,
            sort_by: sortBy,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetRepositoryContributors'],
      }),
      getFoldersData: builder.query<any, any>({
        query: ({ id, searchQuery, pageNum, itemsPerPage, sortBy }) => ({
          url: `/repositories/${id}/folders`,
          params: {
            items_page: itemsPerPage,
            page: pageNum,
            query: searchQuery,
            sort_by: sortBy,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetRepositoryContributors'],
      }),
      getJFrogAuditLog: builder.query<any, any>({
        query: ({ id, searchQuery, pageNum, itemsPerPage, sortBy }) => ({
          url: `/repositories/artifactory-activity/${id}`,
          params: {
            items_page: itemsPerPage,
            page: pageNum,
            query: searchQuery,
            sort_by: sortBy,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetRepositoryContributors'],
      }),
      getRepositoryTeamsList: builder.query<
        RepositoriesTeamsObjRes,
        {
          id: string;
          searchQuery: string;
          sortBy: string;
          source: any;
        }
      >({
        query: ({ id, searchQuery, sortBy, source }) => ({
          url: `repositories/teams/${id}`,
          params: {
            name: searchQuery,
            sort_by: sortBy,
            source: source,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetRepositoryTeams'],
      }),
      getAssetsDetailsPipelinesList: builder.query<
        AssetsDetailsPipelineRes[],
        {
          id: string;
          pageNum: number;
          startDate?: string;
          endDate?: string;
          itemsPerPage: number;
          source: any;
          sortBy: string;
        }
      >({
        query: ({
          id,
          pageNum,
          startDate,
          endDate,
          itemsPerPage,
          source,
          sortBy,
        }) => ({
          url: `/repositories/pipeline/activity/${encodeURIComponent(id)}`,
          params: {
            items_page: itemsPerPage,
            page: pageNum,
            start_date: startDate,
            end_date: endDate,
            source: source,
            sort_by: sortBy,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getAssetsDetailsActivityList: builder.query<
        AssetsDetailsActivityRes[],
        {
          id: string;
          pageNum: number;
          searchQuery: string;
          startDate?: string;
          endDate?: string;
          itemsPerPage: number;
          source: any;
          sortBy?: string;
        }
      >({
        query: ({
          id,
          pageNum,
          startDate,
          endDate,
          itemsPerPage,
          source,
          sortBy,
          searchQuery,
        }) => ({
          url: `/repositories/activity/${encodeURIComponent(id)}`,
          params: {
            items_page: itemsPerPage,
            page: pageNum,
            query: searchQuery,
            start_date: startDate,
            end_date: endDate,
            source: source,
            sort_by: sortBy,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getAssetsDetailsPullRequestList: builder.query<
        any,
        {
          id: string;
          pageNum: number;
          searchQuery: string;
          startDate?: string;
          endDate?: string;
          itemsPerPage: number;
          merged?: boolean;
          sortBy?: string;
        }
      >({
        query: ({
          id,
          pageNum,
          startDate,
          endDate,
          itemsPerPage,
          sortBy,
          searchQuery,
          merged,
        }) => ({
          url: `/repositories/pull_activity/${encodeURIComponent(id)}`,
          params: {
            items_page: itemsPerPage,
            page: pageNum,
            start_date: startDate,
            end_date: endDate,
            sort_by: sortBy,
            is_merged: merged,
            query: searchQuery,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getAssetsPipelineActivityRunsDetails: builder.query<
        AssetsPipelineRunsDetailsRes[],
        number | null
      >({
        query: (workflowId) =>
          `analytics/workflow/runs?workflowid=${workflowId}`,
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getAssetsDetailsBranchesObj: builder.query<
        AssetsDetailsBranchesObjRes,
        {
          pageNum: number;
          sortBy?: string;
          tableFilters: Filter;
          searchQuery: string;
          id: string;
          itemsPerPage: number;
          source: any;
        }
      >({
        query: ({
          id,
          pageNum,
          sortBy,
          tableFilters: {
            only_environment,
            only_important,
            only_protected,
            only_workflow,
          },
          searchQuery,
          itemsPerPage,
          source,
        }) => ({
          url: `/repositories/branches/${encodeURIComponent(id)}`,
          params: {
            items_page: itemsPerPage,
            page: pageNum,
            sort_by: sortBy,
            only_environment,
            only_important,
            only_protected,
            only_workflow,
            query: searchQuery,
            source: source,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getExternalPackagesObj: builder.query<
        ExternalPackagesObjRes,
        {
          id: string;
          pageNum: number;
          searchQuery: string;
          itemsPerPage: number;
          source: any;
          sortBy: string;
          tableFilters?: Filter;
        }
      >({
        query: ({
          id,
          pageNum,
          searchQuery,
          itemsPerPage,
          source,
          sortBy,
          tableFilters,
        }) => ({
          url: `/repositories/external/${encodeURIComponent(id)}`,
          params: {
            page: pageNum,
            items_page: itemsPerPage,
            query: searchQuery,
            source: source,
            sort_by: sortBy,
            severity: tableFilters?.severity,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getAssetContainers: builder.query<
        AnyNaptrRecord,
        {
          id: string;
          pageNum: number;
          searchQuery: string;
          itemsPerPage: number;
          source: any;
          sortBy: string;
        }
      >({
        query: ({
          id,
          pageNum,
          searchQuery,
          itemsPerPage,
          source,
          sortBy,
        }) => ({
          url: `/repositories/containers/${encodeURIComponent(id)}`,
          params: {
            page: pageNum,
            items_page: itemsPerPage,
            query: searchQuery,
            source: source,
            sort_by: sortBy,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getAssetCompliance: builder.query<
        any,
        {
          id: string;
          pageNum: number;
          searchQuery: string;
          itemsPerPage: number;
          complianceStandard: string;
          source: any;
        }
      >({
        query: ({
          id,
          pageNum,
          searchQuery,
          itemsPerPage,
          complianceStandard,
          source,
        }) => ({
          url: `/repositories/compliance/${encodeURIComponent(id)}`,
          params: {
            page: pageNum,
            items_page: itemsPerPage,
            query: searchQuery,
            compliance_standard: complianceStandard,
            source: source,
          },
        }),
        transformResponse: (response: Record<string, any>) => {
          if (!Array.isArray(response?.data)) {
            return { data: response?.data?.ossf_scorecard_checks };
          }
          return response;
        },
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getAssetsDetailsObj: builder.query<AssetsDetailsObjRes, any>({
        query: ({ assetId, source }) =>
          `/repositories/${encodeURIComponent(
            assetId,
          )}?source=${encodeURIComponent(source)}`,
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetAssetsDetailsObj'],
      }),
      getAssetsSecretsRiskList: builder.query<
        any,
        {
          id: string;
          type: string;
          sortBy: string;
          searchQuery: string;
          pageNum: number;
          tableFilters?: SecretDetectionFiltersProps;
          itemsPerPage: number;
          source?: any;
        }
      >({
        query: ({
          id,
          type,
          sortBy,
          searchQuery,
          pageNum,
          tableFilters,
          itemsPerPage,
          source,
        }) => ({
          url: `/repositories/alerts/${encodeURIComponent(
            type,
          )}/${encodeURIComponent(id)}`,
          params: {
            sort_by: sortBy,
            query: searchQuery,
            page: pageNum,
            items_page: itemsPerPage,
            title: tableFilters?.status_filter,
            source: source,
            severity: tableFilters?.verified,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getAssetVulnerabilityCounts: builder.query<
        any,
        { repo_slug: string; source?: string }
      >({
        query: ({ repo_slug, source }) => ({
          url: `/repositories/${repo_slug}/vulnerability_counts`,
          params: {
            source: source,
          },
        }),
      }),
      changeRepoHide: builder.mutation<void, any>({
        query: ({ id, source }) => ({
          url: `/repositories/hide/${encodeURIComponent(id)}?source=${source}`,
          method: 'PATCH',
        }),
        invalidatesTags: ['GetRepositoriesList', 'GetAssetsDetailsObj'],
      }),
      changeRepoWatch: builder.mutation<void, any>({
        query: ({ id, source }) => ({
          url: `/repositories/watch/${encodeURIComponent(id)}?source=${source}`,
          method: 'PATCH',
        }),
        invalidatesTags: ['GetRepositoriesList', 'GetAssetsDetailsObj'],
      }),
      changeIdentitiesUserHide: builder.mutation<void, any>({
        query: ({ id, source }) => ({
          url: `/users/hide/${encodeURIComponent(
            id,
          )}?source=${encodeURIComponent(source)}`,
          method: 'PATCH',
        }),
        invalidatesTags: ['GetIdentitiesUserList', 'GetIdentitiesUserDetails'],
      }),
      changeIdentitiesUserWatch: builder.mutation<void, any>({
        query: ({ id, source }) => ({
          url: `/users/watch/${encodeURIComponent(
            id,
          )}?source=${encodeURIComponent(source)}`,
          method: 'PATCH',
        }),
        invalidatesTags: ['GetIdentitiesUserList', 'GetIdentitiesUserDetails'],
      }),
      changeTeamHide: builder.mutation<void, any>({
        query: ({ id, source }) => ({
          url: `/users/teams/hide/${encodeURIComponent(id)}?source=${source}`,
          method: 'PATCH',
        }),
        invalidatesTags: ['GetTeamDetailsObj', 'GetTeamsList'],
      }),
      changeTeamWatch: builder.mutation<void, any>({
        query: ({ id, source }) => ({
          url: `/users/teams/watch/${encodeURIComponent(id)}?source=${source}`,
          method: 'PATCH',
        }),
        invalidatesTags: ['GetTeamDetailsObj', 'GetTeamsList'],
      }),
      getStaleRepositories: builder.query<ChartData, void>({
        query: () => ({
          url: '/repositories/stale/chart',
        }),
        transformResponse: (response: Record<string, string>) => {
          return transformStaleRepositoriesResponse(response);
        },
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getArtifactoryRepositoriesList: builder.query<
        any,
        {
          pageNum: number;
          sortBy?: string;
          tableFilters: FiltersProps;
          searchQuery: string;
          itemsPerPage: number;
        }
      >({
        query: ({
          pageNum,
          sortBy,
          tableFilters,
          searchQuery,
          itemsPerPage,
        }) => ({
          url: '/repositories/assets/artifactory',
          params: {
            items_page: itemsPerPage,
            page: pageNum,
            sort_by: sortBy,
            critical: tableFilters?.critical,
            status: tableFilters?.status,
            source: 'JFrog',
            query: searchQuery,
            only_hidden: tableFilters?.only_hidden,
            only_watched: tableFilters?.only_watched,
            only_production: tableFilters?.only_production,
            highest_severity: tableFilters?.highest_severity,
            private: tableFilters?.private,
          },
        }),
        providesTags: ['GetRepositoriesList'],
        transformResponse: (
          response: AssetsRepositoriesObjsRes,
        ): AssetsRepositoriesObj => {
          return transformRepositoriesResponse(response);
        },
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getRepositoriesList: builder.query<
        AssetsRepositoriesObjsRes,
        {
          pageNum: number;
          sortBy?: string;
          tableFilters: FiltersProps;
          searchQuery: string;
          itemsPerPage: number;
        }
      >({
        query: ({
          pageNum,
          sortBy,
          tableFilters,
          searchQuery,
          itemsPerPage,
        }) => ({
          url: '/repositories',
          params: {
            items_page: itemsPerPage,
            page: pageNum,
            sort_by: sortBy,
            critical: tableFilters?.critical,
            status: tableFilters?.status,
            source: tableFilters?.source,
            query: searchQuery,
            only_hidden: tableFilters?.only_hidden,
            only_watched: tableFilters?.only_watched,
            only_production: tableFilters?.only_production,
            highest_severity: tableFilters?.highest_severity,
            private: tableFilters?.private,
            visibility: tableFilters?.visibility,
            max_compliance_score: tableFilters?.max_compliance_score,
          },
        }),
        providesTags: ['GetRepositoriesList'],
        transformResponse: (
          response: AssetsRepositoriesObjsRes,
        ): AssetsRepositoriesObj => {
          return transformRepositoriesResponse(response);
        },
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getDependencyGraphData: builder.query<any, Record<string, any>>({
        query: (params) => {
          return {
            url: '/analytics/entity-graph/access',
            params,
          };
        },
        transformResponse: (response: any) => {
          return transDependencyGraphDataResponse(response);
        },
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getIdentitiesUserList: builder.query<
        IdentitiesUserObj,
        {
          pageNum: number;
          sortBy?: string;
          tableFilters: Filter;
          searchQuery?: string;
          itemsPerPage?: number;
        }
      >({
        query: ({
          pageNum,
          sortBy,
          tableFilters,
          searchQuery,
          itemsPerPage,
        }) => ({
          url: '/users',
          params: {
            items_page: itemsPerPage || 30,
            page: pageNum,
            sort_by: sortBy,
            account_status: tableFilters?.account_status,
            team_member: tableFilters?.team_member,
            internal_account: tableFilters?.internal_account,
            external_account: tableFilters?.external_account,
            highest_severity: tableFilters?.highest_severity,
            min_overprivilege_score: tableFilters?.min_overprivilege_score,
            unknown: tableFilters?.unknown,
            account_type: tableFilters?.account_type,
            is_admin: tableFilters?.is_admin,
            source: tableFilters?.source,
            query: searchQuery,
            only_watched: tableFilters?.only_watched,
            only_hidden: tableFilters?.only_hidden,
          },
        }),
        providesTags: ['GetIdentitiesUserList'],
        transformResponse: (
          response: IdentitiesUserObjRes,
        ): IdentitiesUserObj => {
          return transformIdentitiesUsersResponse(response);
        },
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      GetSastScanData: builder.query<
        any,
        {
          pageNum: number;
          sortBy?: string;
          tableFilters: Filter;
          searchQuery?: string;
          itemsPerPage?: number;
          severity?: any;
        }
      >({
        query: ({ pageNum, sortBy, itemsPerPage, severity, tableFilters }) => ({
          url: '/findings/sast/detections',
          params: {
            items_page: itemsPerPage || 30,
            page: pageNum,
            sort_by: sortBy,
            severity,
            state: tableFilters?.state,
          },
        }),
        providesTags: ['GetSastScanData'],
      }),
      GetTeamVulnerabilityReportData: builder.query<
        any,
        {
          pageNum: number;
          sortBy?: string;
          itemsPerPage?: number;
        }
      >({
        query: ({ pageNum, sortBy, itemsPerPage }) => ({
          url: '/users/teams/vulnerabilities',
          params: {
            items_page: itemsPerPage || 10,
            page: pageNum,
            sort_by: sortBy,
          },
        }),
        providesTags: ['GetSastScanData'],
      }),
      GetIacScanData: builder.query<
        any,
        {
          pageNum: number;
          sortBy?: string;
          tableFilters: Filter;
          searchQuery?: string;
          itemsPerPage?: number;
          severity?: any;
        }
      >({
        query: ({ pageNum, sortBy, itemsPerPage, severity }) => ({
          url: '/findings/iac/detections',
          params: {
            items_page: itemsPerPage || 30,
            page: pageNum,
            sort_by: sortBy,
            severity,
          },
        }),
        providesTags: ['GetIacScanData'],
      }),
      getTrivyScanData: builder.query<
        any,
        {
          pageNum: number;
          sortBy?: string;
          tableFilters: Filter;
          searchQuery?: string;
          itemsPerPage?: number;
          severity?: any;
        }
      >({
        query: ({ pageNum, sortBy, itemsPerPage }) => ({
          url: '/findings/github/terraform/checkruns',
          params: {
            items_page: itemsPerPage || 30,
            page: pageNum,
            sort_by: sortBy,
          },
        }),
        providesTags: ['GetTrivyScanData'],
      }),
      getTrivyScanDetails: builder.query<
        any,
        {
          org: string;
          repo: string;
          pr_number: number;
          check_run_id: number;
        }
      >({
        query: ({ org, repo, pr_number, check_run_id }) => ({
          url: `/findings/github/terraform/${org}/${repo}/${pr_number}/${check_run_id}`,
        }),
      }),
      getPRSecretScanData: builder.query<
        any,
        {
          pageNum: number;
          sortBy?: string;
          tableFilters: Filter;
          searchQuery?: string;
          itemsPerPage?: number;
          severity?: any;
        }
      >({
        query: ({ pageNum, sortBy, itemsPerPage }) => ({
          url: '/findings/github/secrets/checkruns',
          params: {
            items_page: itemsPerPage || 30,
            page: pageNum,
            sort_by: sortBy,
          },
        }),
        providesTags: ['GetPRSecretScanData'],
      }),
      getPRSecretScanDetails: builder.query<
        any,
        {
          org: string;
          repo: string;
          pr_number: number;
          check_run_id: number;
        }
      >({
        query: ({ org, repo, pr_number, check_run_id }) => ({
          url: `/findings/github/secrets/${org}/${repo}/${pr_number}/${check_run_id}`,
        }),
      }),
      getHealthOverviewChart: builder.query<
        HealthOveriewChartItemRes[],
        {
          tableFilters: Filter;
        }
      >({
        query: ({ tableFilters }) => ({
          url: '/analytics/health-overview-chart',
          params: {
            highest_severity: tableFilters?.highest_severity,
            min_overprivilege_score: tableFilters?.min_overprivilege_score,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetHealthOverviewChartData'],
      }),
      getRepositoriesExternalList: builder.query<
        OpenSourceDependenciesObjRes,
        {
          pageNum: number;
          searchQuery: string;
          sortBy?: string;
          tableFilters: ExternalCodeFiltersProps;
          itemsPerPage: number;
        }
      >({
        query: ({
          pageNum,
          searchQuery,
          sortBy,
          tableFilters: {
            license,
            advisories,
            risk_rating_filter,
            language,
            lte_total_score,
            gte_total_score,
          },
          itemsPerPage,
        }) => ({
          url: `/repositories/external/list`,
          params: {
            page: pageNum,
            items_page: itemsPerPage,
            query: searchQuery,
            sort_by: sortBy,
            license_filter: license,
            advisories,
            risk_rating_filter,
            language,
            gte_total_score:
              lte_total_score && Number(lte_total_score) === 100
                ? 68
                : lte_total_score && Number(lte_total_score) === 67
                ? 41
                : sortBy && sortBy.substring(1) === 'total_score'
                ? 0
                : gte_total_score,
            lte_total_score:
              typeof lte_total_score === 'string'
                ? Number(lte_total_score)
                : lte_total_score,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetRepositoriesExternalList'],
      }),
      getRepositoriesExternalTree: builder.query<
        RawNodeDatum,
        { repoSlug: string; path: string; filePath: string }
      >({
        query: ({ repoSlug, path, filePath }) => ({
          url: `/repositories/external/tree/${encodeURIComponent(repoSlug)}`,
          method: 'POST',
          body: { package_manager: path, file_path: filePath },
        }),

        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['RepositoriesExternalTree'],
      }),
      getInsightsGeolocation: builder.query<any, any>({
        query: ({ userId }) => `/users/geolocation-count?user_login=${userId}`,
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetInsightsGeolocation'],
      }),
      getCommitJourneyTree: builder.query<RawNodeDatum, any>({
        query: ({ repo_slug, commit_sha, workflow_num }) => ({
          url: `/repositories/v2/get-commit-details/${repo_slug}/${commit_sha}`,
          params: {
            workflow_num: workflow_num,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getCommitJourneyTreeWithWorkflow: builder.query<RawNodeDatum, any>({
        query: ({ owner, repo, commit_sha, workflow_run }) =>
          `/repositories/v2/get-commit-details/${encodeURIComponent(
            owner,
          )}:${encodeURIComponent(repo)}/${encodeURIComponent(
            commit_sha,
          )}?workflow_num=${encodeURIComponent(workflow_run)}`,
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getPipelineCommitTree: builder.query<RawNodeDatum, string>({
        query: (commit_sha) => `/repositories/commit/pipeline/${commit_sha}`,
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getOnboardingChecklistStatus: builder.query<Filter, void>({
        query: () => ({
          url: '/users/onboarding-checklist/status',
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetOnboardingChecklistStatus'],
      }),
      getDetectedSecretsList: builder.query<
        SecretDetectionObjRes,
        {
          pageNum: number;
          // searchQuery: string;
          sortBy?: string;
          tableFilters?: SecretDetectionFiltersProps;
          startDate?: string;
          endDate?: string;
        }
      >({
        query: ({
          pageNum,
          // searchQuery,
          sortBy,
          tableFilters,
          startDate,
          endDate,
        }) => ({
          url: `/secrets/detections`,
          params: {
            page: pageNum,
            items_page: 100,
            // query: searchQuery,
            sort_by: sortBy,
            query: tableFilters?.description,
            start_date: startDate,
            end_date: endDate,
          },
        }),
        transformResponse: (response: {
          detections?: SecretDetectionItem[];
          page: number;
          items_page: number;
          total?: number;
        }): SecretDetectionObjRes => {
          const returnObject = {
            page: response.page,
            items_page: response.items_page,
            data: response?.detections || [],
            total: response?.total || 100,
          };
          return returnObject;
        },
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetDetectedSecretsList'],
      }),
      getDetectedSecretTypes: builder.query<
        any,
        {
          startDate?: string;
          endDate?: string;
          source?: any;
        }
      >({
        query: ({ startDate, endDate, source }) => ({
          url: '/secrets/detections/types',
          params: {
            start_date: startDate,
            end_date: endDate,
            source: source,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getSecretByType: builder.query<
        any,
        { secretType: string; startDate: string; endDate: string }
      >({
        query: ({ secretType, startDate, endDate }) => ({
          url: `/secrets/detection/${secretType}`,
          params: {
            start_date: startDate,
            end_date: endDate,
            items_page: 100,
            page: 1,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetSecretByType'],
      }),
      updateSecret: builder.mutation<void, { status: string; id: string }>({
        query: (secret) => ({
          url: `/secrets/detections`,
          method: 'POST',
          body: { ...secret },
        }),
        invalidatesTags: ['GetDetectedSecretsList'],
      }),
      getSlackInfo: builder.query<any, void>({
        query: () => ({
          url: '/rules/slack/info',
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getJiraInfo: builder.query<any, void>({
        query: () => ({
          url: '/rules/jira/info',
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getJiraIssueTypes: builder.query<any, any>({
        query: ({ projectKey }) => ({
          url: `/rules/jira/get-issue-types?project_key=${projectKey}`,
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getJiraFields: builder.query<any, any>({
        query: ({ project, issueType }) => ({
          url: `/rules/jira/available-fields?project=${project}&issue_type=${issueType}`,
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      addConnectorAction: builder.mutation<any, any>({
        query: ({ policyName, action }) => {
          return {
            url: `/rules/policy/action`,
            params: {
              policy_name: policyName,
            },
            method: 'POST',
            body: action,
          };
        },
        invalidatesTags: [
          'CurrentConnections',
          'GetPolicyByName',
          'GetPolicyCriteriaByName',
        ],
      }),
      editConnectorAction: builder.mutation<any, any>({
        query: ({ policyName, action }) => {
          return {
            url: `/rules/policy-action`,
            params: {
              policy_name: policyName,
            },
            method: 'PUT',
            body: action,
          };
        },
        invalidatesTags: [
          'CurrentConnections',
          'GetPolicyByName',
          'GetPolicyCriteriaByName',
        ],
      }),
      deleteConnectorAction: builder.mutation<any, any>({
        query: ({ policyName, connectorId }) => {
          return {
            url: `/rules/policy/action`,
            params: {
              policy_name: policyName,
              connector_id: connectorId,
            },
            method: 'PATCH',
          };
        },
        invalidatesTags: [
          'CurrentConnections',
          'GetPolicyByName',
          'GetPolicyCriteriaByName',
        ],
      }),
      getSettingsInfo: builder.query<any, void>({
        query: () => ({
          url: '/admin/settings',
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetSettingsInfo'],
      }),
      updateSettings: builder.mutation<any, any>({
        query: ({ body }) => {
          return {
            url: `/admin/settings`,
            method: 'PUT',
            body,
          };
        },
        invalidatesTags: ['GetSettingsInfo'],
      }),
      getReportsList: builder.query<
        ReportObjRes,
        { searchQuery: string; pageNum: number; itemsPerPage: number }
      >({
        query: ({ searchQuery, pageNum, itemsPerPage }) => ({
          url: '/analytics/reports/list',
          params: {
            items_page: itemsPerPage,
            page: pageNum,
            query: searchQuery,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      sendCSVReportOnEmail: builder.mutation<
        void,
        { email: string; filePath: string }
      >({
        query: ({ email, filePath }) => ({
          url: `/analytics/reports/csv-report/${email}`,
          method: 'GET',
          params: {
            file_path: filePath,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      deleteReport: builder.mutation<
        void,
        { hashKey: string; filePath: string }
      >({
        query: ({ hashKey, filePath }) => ({
          url: `/analytics/reports/delete`,
          method: 'DELETE',
          body: {
            hash_key: hashKey,
            file_path: filePath,
          },
        }),
      }),
      getContributors: builder.query<any, string>({
        query: (id) => ({
          url: `/oss/package/health/${id}`,
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetContributors'],
      }),
      getExposedRepositories: builder.query<
        ExposedRepositoriesObjRes,
        {
          id: string;
          pageNum: number;
          sortBy?: string;
          packageMgr: string;
          tableFilters: Filter;
          itemsPerPage: number;
        }
      >({
        query: ({
          id,
          tableFilters,
          pageNum,
          sortBy,
          packageMgr,
          itemsPerPage,
        }) => ({
          url: `/repositories/external/exposed-repositories/${id}`,
          params: {
            repo_status: tableFilters?.repo_status,
            severity: tableFilters?.severity,
            page: pageNum,
            sort_by: sortBy,
            package_manager: packageMgr,
            items_page: itemsPerPage,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetExposedRepositories'],
      }),
      getExternalPackageVulnerabilityDetails: builder.query<
        any,
        {
          pkg?: string;
          version?: string;
          pkgManager?: string;
        }
      >({
        query: ({ pkg, version, pkgManager }) => ({
          url: `/oss/package/vulnerability-details/${pkg}/${version}?package_manager=${pkgManager}`,
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getOSSPackageLanguages: builder.query<any, void>({
        query: () => `oss/package/languages`,
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getIdentityRiskReasons: builder.query<any, any>({
        query: (userId) =>
          `users/violations/${encodeURIComponent(userId)}?page=1&items_page=10`,
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getRepositoryRiskReasons: builder.query<any, any>({
        query: (repoSlug) =>
          `repositories/violations/${encodeURIComponent(
            repoSlug,
          )}?page=1&items_page=10`,
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getActivityAudit: builder.query<
        IdentityAuditActivityListRes,
        {
          id: string;
          pageNum: number;
          startDate: string;
          endDate: string;
          itemsPerPage: number;
          source?: any;
        }
      >({
        query: ({ id, pageNum, startDate, endDate, itemsPerPage, source }) => ({
          url: `/users/audit/pipeline/${encodeURIComponent(id)}`,
          params: {
            page: pageNum,
            items_page: itemsPerPage,
            source: source,
            // start_date: startDate,
            // end_date: endDate,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      triggerRemediation: builder.mutation<any, any>({
        query: ({ body }) => ({
          url: `/recommendations/trigger`,
          method: 'POST',
          body,
        }),
      }),
      getRecommendationsStatusList: builder.query<
        RecommendationsStatusRes,
        {
          pageNum: number;
          itemsPerPage: number;
        }
      >({
        query: ({ pageNum, itemsPerPage }) => ({
          url: 'recommendations/jobs',
          params: {
            page: pageNum,
            items_page: itemsPerPage,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),

      getRecommendationCountList: builder.query<
        any,
        {
          id: string;
          pageNum: number;
          itemsPerPage: number;
        }
      >({
        query: ({ id, pageNum, itemsPerPage }) => ({
          url: `recommendations/get-recommendation-count/${id}`,
          params: {
            page: pageNum,
            items_page: itemsPerPage,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getRecommendationList: builder.query<
        any,
        {
          id: string;
          pageNum: number;
          itemsPerPage: number;
        }
      >({
        query: ({ id, pageNum, itemsPerPage }) => ({
          url: `recommendations/get-all-recommendation-count/${id}`,
          params: {
            page: pageNum,
            items_page: itemsPerPage,
          },
        }),
      }),
      getRecommendationTotalListByEntityType: builder.query<any, any>({
        query: () => ({
          url: `recommendations/get-recommendation-count`,
        }),
      }),
      getRecommendationDetailsData: builder.query<
        any,
        {
          id: string;
          pageNum: number;
          itemsPerPage: number;
        }
      >({
        query: ({ id, pageNum, itemsPerPage }) => ({
          url: `recommendations/get-recommendation-details/${id}`,
          params: {
            page: pageNum,
            items_page: itemsPerPage,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getIdentityActivitySeriesData: builder.query<any, any>({
        query: ({ id, startDate, endDate, source }) => ({
          url: `users/activities/timeseries/${encodeURIComponent(id)}`,
          params: {
            start_date: startDate,
            end_date: endDate,
            source: source,
          },
        }),
        transformResponse: (
          response: any,
        ): { date: string; activities: string }[] => {
          const dataArray = Object.keys(response?.data).map((key: string) => {
            return {
              date: key,
              activities: response?.data[key]?.activities,
              suspicious_activities: response?.data[key]?.suspicious_activities,
            };
          });
          return dataArray.sort(
            (
              a: { date: string; activities: string },
              b: { date: string; activities: string },
            ) =>
              moment(a?.date, 'YYYY-MM-DD').toDate().getTime() -
              moment(b?.date, 'YYYY-MM-DD').toDate().getTime(),
          );
        },
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getTeamRepoRecommendation: builder.query({
        query: ({ id, source }) => ({
          url: `users/teams/repo-recommendations/${encodeURIComponent(
            id,
          )}?source=${source}`,
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetTeamRepoRecommendation'],
      }),
      getTeamMemberRecommendation: builder.query({
        query: ({ id, source }) => ({
          url: `users/teams/member-recommendations/${encodeURIComponent(
            id,
          )}?source=${source}`,
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetTeamMemberRecommendation'],
      }),
      getIdentityPipelineSeriesData: builder.query<any, any>({
        query: ({ id, startDate, endDate, source }) => ({
          url: `users/activities/pipeline/timeseries/${encodeURIComponent(id)}`,
          params: {
            start_date: startDate,
            end_date: endDate,
            source: source,
          },
        }),
        transformResponse: (
          response: any,
        ): { date: string; activities: string }[] => {
          const dataArray = Object.keys(response?.data).map((key: string) => {
            return {
              date: key,
              activities: response?.data[key]?.activities,
              suspicious_activities: response?.data[key]?.suspicious_activities,
            };
          });
          return dataArray.sort(
            (
              a: { date: string; activities: string },
              b: { date: string; activities: string },
            ) =>
              moment(a?.date, 'YYYY-MM-DD').toDate().getTime() -
              moment(b?.date, 'YYYY-MM-DD').toDate().getTime(),
          );
        },
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getAssetActivitySeriesData: builder.query<any, any>({
        query: ({ id, startDate, endDate }) => ({
          url: `repositories/activities/timeseries/${id}`,
          params: {
            start_date: startDate,
            end_date: endDate,
          },
        }),
        transformResponse: (
          response: any,
        ): { date: string; activities: string }[] => {
          const dataArray = Object.keys(response?.data).map((key: string) => {
            return {
              date: key,
              activities: response?.data[key]?.activities,
              suspicious_activities: response?.data[key]?.suspicious_activities,
            };
          });
          return dataArray.sort(
            (
              a: { date: string; activities: string },
              b: { date: string; activities: string },
            ) =>
              moment(a?.date, 'YYYY-MM-DD').toDate().getTime() -
              moment(b?.date, 'YYYY-MM-DD').toDate().getTime(),
          );
        },
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getAssetPipelineSeriesData: builder.query<any, any>({
        query: ({ id, startDate, endDate }) => ({
          url: `repositories/activities/pipeline/timeseries/${id}`,
          params: {
            start_date: startDate,
            end_date: endDate,
          },
        }),
        transformResponse: (
          response: any,
        ): { date: string; activities: string }[] => {
          const dataArray = Object.keys(response?.data).map((key: string) => {
            return {
              date: key,
              activities: response?.data[key]?.activities,
            };
          });
          return dataArray.sort(
            (
              a: { date: string; activities: string },
              b: { date: string; activities: string },
            ) =>
              moment(a?.date, 'YYYY-MM-DD').toDate().getTime() -
              moment(b?.date, 'YYYY-MM-DD').toDate().getTime(),
          );
        },
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getRecommendationsOverviewList: builder.query<
        RecommendationsOverviewDataRes,
        { pageNum: number; searchQuery: string; itemsPerPage: number }
      >({
        query: ({ pageNum, searchQuery, itemsPerPage }) => ({
          url: '/analytics/teams/recommendations',
          params: {
            page: pageNum,
            items_page: itemsPerPage,
            query: searchQuery,
          },
        }),
      }),
      simulateMerge: builder.mutation<any, { teams: string[] }>({
        query: ({ teams }) => ({
          url: '/analytics/teams/simulate/merge',
          method: 'POST',
          body: teams,
        }),
      }),
      getRecommendationDetails: builder.query<
        any,
        { recommendationId: string }
      >({
        query: ({ recommendationId }) => ({
          url: `/analytics/teams/recommendation/details?recommendation_id=${recommendationId}`,
        }),
      }),

      getComplianceOverviewList: builder.query<
        ComplianceOverviewDataRes,
        { pageNum: number; searchQuery: string; itemsPerPage: number }
      >({
        query: ({ pageNum, searchQuery, itemsPerPage }) => ({
          url: '/compliance/overview',
          params: {
            page: pageNum,
            items_page: itemsPerPage,
            query: searchQuery,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getComplianceStandardList: builder.query<
        any,
        { complianceStandard: string }
      >({
        query: ({ complianceStandard }) => ({
          url: `/compliance/${complianceStandard}`,
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      skipComplianceStandardPolicy: builder.mutation<
        any,
        { complianceStandard: string; policy: string; notes: string }
      >({
        query: ({ complianceStandard, policy, notes }) => ({
          url: `/compliance/skip/${complianceStandard}/${policy}`,
          method: 'POST',
          body: {
            notes,
          },
        }),
      }),
      getComplianceStandardPolicyDetails: builder.query<
        CompliancePolicyDetailsRes,
        { complianceStandard: string; compliancePolicyId: string }
      >({
        query: ({ complianceStandard, compliancePolicyId }) => ({
          url: `/compliance/details/${complianceStandard}/${compliancePolicyId}`,
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getComplianceStandardPolicyList: builder.query<
        any,
        {
          complianceStandard: string;
          compliancePolicyId: string;
          pageNum: number;
          itemsPerPage: number;
        }
      >({
        query: ({
          complianceStandard,
          compliancePolicyId,
          pageNum,
          itemsPerPage,
        }) => ({
          url: `/compliance/data/${complianceStandard}/${compliancePolicyId}`,
          params: {
            page: pageNum,
            items_page: itemsPerPage,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      ignoreComplianceStandardPolicyResource: builder.mutation<
        any,
        {
          complianceStandard: string;
          policy: string;
          notes: string;
          detection: any;
          ignore: boolean;
        }
      >({
        query: ({ complianceStandard, policy, notes, detection, ignore }) => ({
          url: `/compliance/ignore/${complianceStandard}/${policy}`,
          method: 'POST',
          body: {
            detection,
            ignore,
            notes,
          },
        }),
      }),
      enabledManualPolicyChecklist: builder.mutation<
        any,
        {
          complianceStandard: string;
          policy: string;
          enabled: boolean;
          name: string;
        }
      >({
        query: ({ complianceStandard, policy, name, enabled }) => ({
          url: `/compliance/checklist/${complianceStandard}/${policy}`,
          method: 'POST',
          body: {
            name,
            enabled,
          },
        }),
      }),
      addCustomRepositoryOwners: builder.mutation<GeneralResponseItem, any>({
        query: (query) => ({
          url: `/repositories/owners/${encodeURIComponent(query?.id)}`,
          method: 'POST',
          body: query?.payload,
        }),
        invalidatesTags: ['GetRepositoryContributors', 'GetRepositoryTeams'],
      }),
      getGlobalSearchNext: builder.mutation<any, any>({
        query: ({ payload }) => ({
          url: `analytics/search/next`,
          method: 'POST',
          body: payload,
        }),
      }),
      removeCustomRepositoryOwners: builder.mutation<GeneralResponseItem, any>({
        query: (query) => ({
          url: `/repositories/owners/${encodeURIComponent(query?.id)}`,
          method: 'POST',
          body: query?.payload,
        }),
        invalidatesTags: ['GetRepositoryContributors', 'GetRepositoryTeams'],
      }),
      getGlobalSearchStarted: builder.query<any, any>({
        query: () => {
          return {
            url: '/analytics/search/start',
          };
        },
      }),
      editIdentities: builder.mutation<any, any>({
        query: ({ payload }) => ({
          url: `users/identity/edit`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['GetIdentitiesUserList'],
      }),
      getGlobalSearchResult: builder.mutation<any, any>({
        query: ({ payload, params }) => ({
          url: `analytics/search/result`,
          method: 'POST',
          body: payload,
          params,
        }),
      }),
      requestRepositoryAccess: builder.mutation<any, any>({
        query: ({ payload }) => ({
          url: `/recommendations/get-repo-access`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['GetJITRequests'],
      }),
      requestRecurringRepositoryAccess: builder.mutation<any, any>({
        query: ({ payload }) => ({
          url: `/recommendations/get-repo-access-recurring`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['GetJITRequests'],
      }),
      getJITRequestsList: builder.query<any, any>({
        query: ({ pageNum, itemsPerPage, tableFilters, source }) => ({
          url: `/recommendations/repo-requests`,
          params: {
            page: pageNum,
            items_page: itemsPerPage,
            source: source,
            ...tableFilters,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetJITRequests'],
      }),
      processRepositoryAccess: builder.mutation<any, any>({
        query: (payload) => ({
          url: `/recommendations/process-repo-access`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['GetJITRequests'],
      }),
      getPersonalJITRequestsList: builder.query<any, any>({
        query: ({ pageNum, itemsPerPage, source }) => ({
          url: `/recommendations/user/repo-requests`,
          params: {
            page: pageNum,
            items_page: itemsPerPage || 100,
            source: source,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
        providesTags: ['GetPersonalJITRequests'],
      }),
      getContainerSBOM: builder.query<any, any>({
        query: ({ repo, uid, format }) => ({
          url: `/repositories/sbom/container/${repo}`,
          params: {
            uid,
            format,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getCloudFormationTemplate: builder.query<any, any>({
        query: ({ connectorId }) => ({
          url: `/connectors/cft/${connectorId}`,
          method: 'GET',
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getBuildSBOM: builder.query<any, any>({
        query: ({ repo, format }) => ({
          url: `/repositories/sbom/build/${repo}`,
          params: {
            format,
          },
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      getIdentityProvider: builder.query<any, void>({
        query: () => ({
          url: '/admin/identity-providers',
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      addIdentityProvider: builder.mutation<any, any>({
        query: (payload) => ({
          url: `/admin/identity-provider`,
          method: 'POST',
          body: payload,
        }),
      }),
      getAdminGroups: builder.query<any, void>({
        query: () => ({
          url: '/admin/groups',
        }),
        transformErrorResponse: (error: any) => {
          return transformErrorResponse(error);
        },
      }),
      addIdentityProviderMapper: builder.mutation<any, any>({
        query: ({ provider, mapperPayload }) => ({
          url: `/admin/identity-provider/mapper/${provider}`,
          method: 'POST',
          body: mapperPayload,
        }),
      }),
      updateTerraformScanStatus: builder.mutation<any, any>({
        query: (payload) => ({
          url: `/findings/github/terraform/scan-result/status`,
          method: 'POST',
          body: payload,
        }),
      }),
      updateTrufflehogScanStatus: builder.mutation<any, any>({
        query: (payload) => ({
          url: `/findings/github/secrets/scan-result/status`,
          method: 'POST',
          body: payload,
        }),
      }),
    }),
  });

export const defaultBaseApi = generateBaseApi(async () => {
  const { keycloak } = useKeycloak();
  if (keycloak.isTokenExpired()) await keycloak.updateToken();
  return keycloak.refreshToken || '';
});

import { TableColumnProps } from 'components/table/table-component/table-component';

export const repositoriesTeamsTableStructure: TableColumnProps[] = [
  // {
  //   id: 'selection',
  //   title: '',
  //   position: 'left',
  // },
  {
    id: 'team_name',
    title: 'Team',
    position: 'left',
    withSort: true,
  },
  {
    id: 'owner',
    title: 'Organization',
    position: 'left',
    withSort: true,
  },
  {
    id: 'is_owner',
    title: 'Owner',
    position: 'left',
  },
  {
    id: 'role_name',
    title: 'Permissions',
    position: 'left',
    withSort: true,
  },
  {
    id: 'total_members',
    title: 'Total Members',
    position: 'left',
    withSort: true,
  },
  {
    id: 'inactive_members',
    title: 'Inactive Members',
    position: 'left',
    withSort: true,
  },
  {
    id: 'source',
    title: 'Source',
    position: 'left',
    withSort: true,
  },
];

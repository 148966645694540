import { TableColumnProps } from 'components/table/table-component/table-component';

export const membersTableStructure: TableColumnProps[] = [
  {
    id: 'name',
    title: 'Member',
    position: 'left',
    withSort: true,
  },
  {
    id: 'highest_severity',
    title: 'Risk Rating',
    position: 'left',
    withSort: true,
  },
  {
    id: 'overprivilege_score',
    title: 'OverPrivilege score ',
    position: 'left',
  },
  {
    id: 'account_type',
    title: 'Account type',
    position: 'left',
    withSort: true,
  },
  {
    id: 'last_activity',
    title: 'Last Activity',
    isDate: true,
    position: 'left',
    withSort: true,
  },
];

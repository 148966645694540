import { InfoDetailsProps } from 'components/info-component/info-component';
import { TableColumnProps } from 'components/table/table-component/table-component';

export const tableStructureWithoutOrg: TableColumnProps[] = [
  {
    id: 'name',
    title: 'Asset Name',
    withSort: true,
    position: 'left',
  },
  {
    id: 'highest_severity',
    title: 'Risk Rating',
    withSort: true,
    position: 'left',
  },
  {
    id: 'vulnerabilities',
    title: 'Vuln. Counts',
    position: 'left',
  },

  {
    id: 'source',
    title: 'Source',
    withSort: true,
    position: 'left',
  },
  {
    id: 'status',
    title: 'Status',
    withSort: true,
    position: 'left',
  },
  {
    id: 'owners',
    title: 'Inferred Owner',
    withSort: true,
    position: 'left',
  },
  {
    id: 'last_activity',
    title: 'Last Event',
    withSort: true,
    position: 'left',
    isDate: true,
  },
  {
    id: 'is_production',
    title: 'CI/CD Component',
    withSort: true,
    position: 'left',
  },
];

export const tableStructureWithOrg: TableColumnProps[] = [
  {
    id: 'name',
    title: 'Asset Name',
    withSort: true,
    position: 'left',
  },
  {
    id: 'highest_severity',
    title: 'Risk Rating',
    withSort: true,
    position: 'left',
  },
  {
    id: 'vulnerabilities',
    title: 'No of. Vulnerabilities',
    position: 'left',
    withSort: true,
  },
  {
    id: 'ossf_score',
    title: 'OSSF Score',
  },
  {
    id: 'source',
    title: 'Source',
    withSort: true,
    position: 'left',
  },
  {
    id: 'visibility',
    title: 'Visibility',
    position: 'left',
    withSort: true,
  },
  {
    id: 'org',
    title: 'Organization',
    withSort: true,
    position: 'left',
  },
  {
    id: 'status',
    title: 'Status',
    withSort: true,
    position: 'left',
  },
  {
    id: 'last_activity',
    title: 'Last Event',
    withSort: true,
    position: 'left',
    isDate: true,
  },
  {
    id: 'is_production',
    title: 'CI/CD Component',
    withSort: true,
    position: 'left',
  },
];

export const assetInfoStructure: InfoDetailsProps[] = [
  {
    id: 'name',
    title: 'Asset',
  },
  {
    id: 'highest_severity',
    title: 'Risk Rating',
  },
  {
    id: 'source',
    title: 'Source',
  },
  {
    id: 'visibility',
    title: 'Visibility',
  },

  {
    id: 'vulnerabilities',
    title: 'No. of Vulnerabilities',
  },

  {
    id: 'org',
    title: 'Organization',
  },
  {
    id: 'last_activity',
    title: 'Last Event',
    isDate: true,
  },
  {
    id: 'first_commit',
    title: 'First Event',
    isDate: true,
  },
  {
    id: 'entity_graph',
    title: 'Graph',
  },
];

export const assetInfoStructureJFrog: InfoDetailsProps[] = [
  {
    id: 'name',
    title: 'Asset',
  },
  {
    id: 'source',
    title: 'Source',
  },
  {
    id: 'org',
    title: 'Organization',
  },
  {
    id: 'last_activity',
    title: 'Last Event',
    isDate: true,
  },
  {
    id: 'first_commit',
    title: 'First Event',
    isDate: true,
  },
];
